import React from "react";

export function YoutubeEmbed({ embedId }) {
  return (
    <>
      <div className="video-responsive">
        <iframe
          id="player"
          width="853"
          height="480"
          src={`https://www.youtube.com/embed/${embedId}?autoplay=1&controls=0&playsinline=1`}
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; allow-presentation;"
          allowFullScreen
          title="Getting started with Creately video"
        />
      </div>
    </>
  );
}
