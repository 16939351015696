// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import TourCard from "../components/TourCard";
import Layout from "./app.layout";
import { useEffect, useState } from "react";
import TourControls from "../components/TourControls";

const TOUR_CARDS = [
  {
    id: 0,
    heading: "Welcome to Creately!",
    description: "Would you like us to show you around?",
    videoId: "2dppwut02j",
    isWistia: true,
  },
  {
    id: 9,
    heading: "Creately just got a major update!",
    description:
      "Watch this 1 min video for a quick intro so you know where things are.",
    videoId: "0v42it9gyp",
    isWistia: true,
  },
  {
    id: 1,
    heading: "Add a shape",
    description: "You can drag and drop shapes from the library",
    videoId: "https://creately-tour-videos.s3.amazonaws.com/1.mp4",
  },
  {
    id: 2,
    heading: "Connected shapes",
    description:
      "Use the + button to create the next shape and to connect in one go!",
    videoId: "https://creately-tour-videos.s3.amazonaws.com/2.mp4",
  },
  {
    id: 3,
    heading: "Style your diagram",
    description:
      "Make everything look great with the styling and color panels.",
    videoId: "https://creately-tour-videos.s3.amazonaws.com/3.mp4",
  },
  {
    id: 4,
    heading: "Use shortcuts",
    description: "All the good stuff is in the shortcuts.",
    videoId: "https://creately-tour-videos.s3.amazonaws.com/4.mp4",
  },
  {
    id: 5,
    heading: "Invite others",
    description: "All the good stuff is in the shortcuts.",
    videoId: "https://creately-tour-videos.s3.amazonaws.com/5.mp4",
  },
  {
    id: 6,
    heading: "You are all set!",
    description: "Start drawing!",
    image: "/images/tour-end.svg",
  },
];

export default function TourPage() {
  const [swiperController, setSwiperController] = useState(null);
  const [renderTick, setRenderTick] = useState(0);

  useEffect(() => {
    const tourVideoLinks = TOUR_CARDS.filter((card) => card.videoId).map(
      (card) => card.videoId
    );

    tourVideoLinks.forEach((link) => {
      const el = document.createElement("link");
      el.setAttribute("rel", "prefetch");
      el.setAttribute("href", link);
      el.setAttribute("as", "video");
      el.setAttribute("type", "video/mp4");
      document.head.appendChild(el);
    });
    document.head.setAttribute("tour-preloaded", "true");
  }, []);

  return (
    <Layout bgClass="bg-white">
      <div className="w-full px-2 sm:px-0 sm:w-4/5 lg:3/5 xl:w-2/5 h-screen pt-8 mx-auto">
        <div className="bg-creately-theme-primary px-6 rounded-tl-md rounded-tr-md">
          <Swiper
            spaceBetween={50}
            slidesPerView={1}
            onSlideChange={() => setRenderTick(renderTick + 1)}
            onSwiper={setSwiperController}
          >
            {TOUR_CARDS.map((card) => (
              <SwiperSlide key={card.id}>
                {({ isActive }) =>
                  isActive ? (
                    <TourCard card={card} />
                  ) : (
                    <span className="block w-2/3"></span>
                  )
                }
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
        <div className="flex items-center justify-end w-full pt-3">
          <TourControls key={renderTick} controller={swiperController} />
        </div>
      </div>
    </Layout>
  );
}
