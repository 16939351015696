import { useState, useMemo } from "react";
import { useMutation, useQuery } from "@apollo/client";
import { Link, useRouteMatch, useParams, useHistory } from "react-router-dom";
import {
  ChevronRightIcon,
  LightningBoltIcon,
  PlusIcon,
} from "@heroicons/react/solid";
import { orderBy, get } from "lodash";
import {
  CREATE_TEAM_WORKSPACE,
  GET_RECENT_TEAM_WORKSPACES,
} from "../queries/channel";
import Layout from "../common/app.layout";
import FolderThumbnail from "../components/FolderThumbnail";
import SearchItem from "../components/SearchItem";
import Modal from "../components/Modal";
import Spinner from "../components/Spinner";
import WorkspaceCardList from "../components/WorkspaceCardList";
import FolderSearch from "../components/FolderSearch";
import { BrowserView } from "react-device-detect";
import { useTelemetry } from "../contexts/tacker.context";

export default function ChannelDashboardPage({ folder }) {
  const [isOpen, setIsOpen] = useState(false);
  const { url } = useRouteMatch();
  const { folderId } = useParams();
  const history = useHistory();
  const { telemetry } = useTelemetry();

  const { loading: diagramsLoading, data: allTeamWorkspaceResponse } = useQuery(
    GET_RECENT_TEAM_WORKSPACES,
    {
      variables: { folderId },
      fetchPolicy: "no-cache",
    }
  );

  const latestTeamWorkspaces = useMemo(() => {
    if (!allTeamWorkspaceResponse) return null;
    const wkspSharedByOthers = get(
      allTeamWorkspaceResponse,
      "folder.documents"
    );
    return orderBy(wkspSharedByOthers, ["lastUpdated"], "desc").slice(0, 12);
  }, [allTeamWorkspaceResponse]);

  const [documentCreate] = useMutation(CREATE_TEAM_WORKSPACE);

  async function createWorkspace(workspaceName) {
    const {
      data: { documentCreateWithPrivacyLevel: document },
    } = await documentCreate({
      variables: {
        documentInput: { name: workspaceName, projectId: folderId },
      },
      refetchQueries: [GET_RECENT_TEAM_WORKSPACES],
      onCompleted: () => setIsOpen(false),
    });
    return document.id;
  }

  function handleQuickStart() {
    createWorkspace("Untitled Workspace").then((workspaceId) => {
      telemetry.track("workspace.create", {
        value1Type: "Location",
        value1: "Quick Start",
      });
      history.push({
        pathname: `/app/${workspaceId}`,
        state: {
          starter: true,
          dashboardPath: `/channel/${folderId}`,
        },
      });
    });
  }

  return (
    <Layout>
      <Modal
        title="Create Team Workspace"
        placeholder="Your New Workspace"
        buttonText="Create Workspace"
        visible={isOpen}
        onSubmit={(formInput) => {
          createWorkspace(formInput.workspaceName);
          telemetry.track("workspace.create", {
            value1Type: "Location",
            value1: "Typical",
          });
        }}
        onClose={() => setIsOpen(false)}
      />
      <div className="flex-grow">
        <div className="mx-auto px-4 lg:w-4/5 pt-12 mb-16">
          <FolderSearch folderId={folderId}>
            {({ workspaces: searchResults }) =>
              searchResults.map((wksp) => (
                <SearchItem item={wksp} key={wksp.id}>
                  <Link
                    to={{
                      pathname: `/app/${wksp.id}`,
                      state: { dashboardPath: `/channel/${folderId}` },
                    }}
                    className="inline-block my-1"
                  >
                    <span className="text-sm font-semibold text-white bg-creately-theme-primary hover:bg-opacity-90 transition-colors rounded py-1 px-2 shadow">
                      Go to Workspace
                    </span>
                  </Link>
                </SearchItem>
              ))
            }
          </FolderSearch>
        </div>

        {folder ? (
          <div className="mx-auto px-4 lg:w-4/5 mt-6">
            <div className="flex flex-col w-full items-center md:flex-row md:items-start">
              <div className="min-w-[220px]">
                <FolderThumbnail folder={folder} />
              </div>
              <div className="flex-grow px-4 mt-6 md:mt-0 self-stretch">
                <BrowserView>
                  <div className="mb-6">
                    <h3 className="font-semibold text-creately-theme-charade dark:text-creately-theme-secondary dark:text-opacity-70">
                      Get Started from Scratch or find right tools for you
                    </h3>
                    <div className="flex items-center space-x-2 my-2">
                      <button
                        className="flex w-full sm:w-auto text-creately-theme-orange items-center bg-creately-theme-yellow bg-opacity-10 border border-creately-theme-orange p-3 rounded"
                        onClick={handleQuickStart}
                      >
                        <LightningBoltIcon className="w-6 h-6" />

                        <div className="flex flex-col items-start px-3">
                          <h4 className="text-sm font-semibold text-creately-theme-orange">
                            Quick Start
                          </h4>
                          <p className="text-gray-400 text-xs">
                            Use built-in toolkits & templates
                          </p>
                        </div>
                      </button>
                      <button
                        className="flex w-full sm:w-auto text-creately-theme-primary items-center bg-creately-theme-primary bg-opacity-10 border border-creately-theme-primary p-3 rounded"
                        onClick={() => setIsOpen(true)}
                      >
                        <PlusIcon className="w-6 h-6" />

                        <div className="flex flex-col items-start px-3">
                          <h4 className="text-sm font-semibold text-creately-theme-primary">
                            Create a Workspace
                          </h4>
                          <p className="text-gray-400 text-xs">
                            Start from scratch
                          </p>
                        </div>
                      </button>
                    </div>
                  </div>
                </BrowserView>
                <div className="flex justify-between my-2">
                  <h3 className="font-semibold text-creately-theme-charade dark:text-creately-theme-secondary dark:text-opacity-70">
                    Continue your work
                  </h3>
                  <Link
                    to={`${url}/workspaces`}
                    className="flex space-x-2 items-center text-creately-theme-primary dark:text-creately-theme-black text-sm font-semibold hover:underline hover:text-green-500 duration-150 transition-colors"
                  >
                    <span>All Workspaces</span>
                    <span>
                      <ChevronRightIcon className="w-4 h-4" />
                    </span>
                  </Link>
                </div>

                {diagramsLoading && (
                  <Spinner text="Loading Recent Workspaces" />
                )}
                {latestTeamWorkspaces && (
                  <WorkspaceCardList
                    workspaces={latestTeamWorkspaces}
                    dashboardPath={`/channel/${folderId}`}
                    placeholderText="Your team has no recent workspaces yet...!"
                  />
                )}
              </div>
            </div>
          </div>
        ) : null}
      </div>
    </Layout>
  );
}
