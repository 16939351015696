import {
  ApolloClient,
  ApolloProvider,
  HttpLink,
  InMemoryCache,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { useMemo } from "react";
import { useAuth } from "../contexts/auth.context";

export const GQLClientProvider = (props) => {
  const { isTokenValid: isValid, tokenCookie: authCookie } = useAuth();
  // console.log({ isValid, authCookie });
  const gqlClient = useMemo(() => {
    const httpLink = new HttpLink({
      uri: process.env.REACT_APP_CREATELY_API_URL,
    });
    const authLink = setContext((_, { headers }) => {
      return {
        headers: {
          ...headers,
          "creately-gravity-token": isValid && authCookie ? authCookie : "",
        },
      };
    });
    return new ApolloClient({
      link: authLink.concat(httpLink),
      cache: new InMemoryCache(),
    });
  }, [isValid, authCookie]);

  return <ApolloProvider client={gqlClient} {...props} />;
};
