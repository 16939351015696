import { useQuery } from "@apollo/client";
import { Route, Switch, useParams, useRouteMatch } from "react-router-dom";
import ChannelDashboardPage from "./dashboard.page";
import ChannelUpdatesPage from "./updates.page";
import ChannelWorkspacesPage from "./workspaces.page";
import { GET_FOLDER_DETAILS } from "../queries/channel";
import Layout from "../common/app.layout";
import Spinner from "../components/Spinner";

function ChannelIndexPage() {
  const { folderId } = useParams();
  const { path } = useRouteMatch();

  const { loading: folderLoading, data: folderDetailsResponse } = useQuery(
    GET_FOLDER_DETAILS,
    {
      variables: { folderId },
    }
  );

  return (
    <Layout>
      <Switch>
        <Route
          exact
          path={path}
          render={(props) => {
            if (folderLoading) {
              return (
                <Layout>
                  <div className="flex items-center justify-center w-full h-screen">
                    <Spinner text="Loading Folder Details" />
                  </div>
                </Layout>
              );
            }
            return (
              <ChannelDashboardPage
                folder={folderDetailsResponse.folder}
                {...props}
              />
            );
          }}
        />
        <Route exact path={`${path}/updates`} component={ChannelUpdatesPage} />
        <Route
          exact
          path={`${path}/workspaces`}
          component={ChannelWorkspacesPage}
        />
      </Switch>
    </Layout>
  );
}

export default ChannelIndexPage;
