import { Link } from "react-router-dom";
import WorkspaceThumbnail from "./WorkspaceThumbnail";

export default function WorkspaceThumbnailList({
  workspaces,
  dashboardPath,
  placeholderText,
}) {
  if (workspaces.length === 0) {
    return (
      <h4 className="text-center text-sm mt-6 text-creately-status-in-progress">
        {placeholderText}
      </h4>
    );
  }

  return (
    <div className="grid gap-4 sm:grid-cols-2 lg:grid-cols-4 mt-6">
      {workspaces.map((doc) => (
        <Link
          to={{ pathname: `/app/${doc.id}`, state: { dashboardPath } }}
          key={doc.id}
        >
          <WorkspaceThumbnail workspace={doc} />
        </Link>
      ))}
    </div>
  );
}
