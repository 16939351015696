import { Link } from "react-router-dom";
import { ChevronLeftIcon, PlusIcon } from "@heroicons/react/outline";
import { useEffect, useMemo, useState, useCallback } from "react";
import { useMutation, useQuery } from "@apollo/client";
import {
  CREATE_ORG_WORKSPACE,
  FIND_ORG_WORKSPACES,
  GET_ORG_WORKSPACES,
} from "../queries/configure";
import Spinner from "../components/Spinner";
import { RadioGroup } from "@headlessui/react";
import WorkspaceCard from "../components/WorkspaceCard";
import Modal from "../components/Modal";
import { settings as teamsConfig } from "@microsoft/teams-js";
import { getAppUrl } from "../helpers/utils";
import Layout from "../common/app.layout";

import SearchItem from "../components/SearchItem";
import { useTeams } from "../hooks/useTeams";
import { useAuth } from "../contexts/auth.context";
import {
  getTeamsWindowContext,
  TEAMS_WINDOW_CONTEXT,
} from "../helpers/teamsHelper";
import { get, orderBy } from "lodash";
import WorkspaceSearch from "../components/WorkspaceSearch";
import { BrowserView } from "react-device-detect";
import { useTelemetry } from "../contexts/tacker.context";

export default function ConfigureWorkspacesPage() {
  const { user } = useAuth();
  const [isOpen, setIsOpen] = useState(false);
  const [workspaceId, setWorkspaceId] = useState("");
  const [{ teamsContext }] = useTeams();
  const isInsideMeeting = useMemo(() => {
    const teamsWindowContext = getTeamsWindowContext(teamsContext);
    return (
      teamsWindowContext === TEAMS_WINDOW_CONTEXT.config.meeting ||
      teamsWindowContext === TEAMS_WINDOW_CONTEXT.config.group
    );
  }, [teamsContext]);
  const { telemetry } = useTelemetry();

  const { loading, data: orgDiagramsResponse } = useQuery(GET_ORG_WORKSPACES, {
    variables: { id: user?.organization?.id },
  });

  const [documentCreate] = useMutation(CREATE_ORG_WORKSPACE);

  const workspaceInfo = useMemo(() => {
    if (!workspaceId || !orgDiagramsResponse) return null;
    return orgDiagramsResponse.organization.documents.find(
      (doc) => doc.id === workspaceId
    );
  }, [workspaceId, orgDiagramsResponse]);

  useEffect(() => {
    if (!teamsContext) return;
    if (
      teamsContext.theme === "dark" ||
      window.matchMedia("(prefers-color-scheme: dark)").matches
    ) {
      document.documentElement.classList.add("dark");
    }

    return () => document.documentElement.classList.remove("dark");
  }, [teamsContext]);

  const canUserCollaborate = useCallback(
    (wksp) =>
      wksp.collaborators?.length > 1 &&
      wksp.collaborators.filter(
        (collab) =>
          collab.user.id === user.id && ["0", "5"].includes(collab.user.role)
      ).length > 0,
    [user]
  );

  const filterFn = useMemo(() => {
    if (isInsideMeeting) {
      return (wksp) =>
        ["team", "teamLink"].includes(wksp.privacy) || canUserCollaborate(wksp);
    }
    return (wksp) => ["team", "teamLink"].includes(wksp.privacy);
  }, [isInsideMeeting, canUserCollaborate]);

  const teamWorkspaces = useMemo(() => {
    if (!orgDiagramsResponse) return null;
    const orgWorkspaces = get(
      orgDiagramsResponse,
      "organization.documents"
    ).filter(filterFn);
    return orderBy(orgWorkspaces, ["lastUpdated"], "desc");
  }, [orgDiagramsResponse, filterFn]);

  async function createWorkspace(workspaceName) {
    const {
      data: { documentCreateWithPrivacyLevel: document },
    } = await documentCreate({
      variables: {
        documentInput: { name: workspaceName },
      },
      refetchQueries: [GET_ORG_WORKSPACES],
      onCompleted: () => setIsOpen(false),
    });
    setWorkspaceId(document.id);
  }

  useEffect(() => {
    teamsConfig.registerOnSaveHandler(function (saveEvent) {
      var tabUrl = getAppUrl(`/app/${workspaceId}`);
      teamsConfig.setSettings({
        contentUrl: tabUrl, // Mandatory parameter
        entityId: tabUrl, // Mandatory parameter
        suggestedDisplayName: workspaceInfo?.name || "Creately Diagram",
      });
      if (isInsideMeeting) {
        telemetry.track("workspace.meeting.pin");
      } else {
        telemetry.track("workspace.channel.pin", {
          value1Type: "Pinned Location",
          value1: "Workspace",
        });
      }
      saveEvent.notifySuccess();
    });

    teamsConfig.setValidityState(!!workspaceId);
  }, [workspaceId, workspaceInfo, isInsideMeeting, telemetry]);

  return (
    <Layout bgClass="bg-white dark:bg-transparent">
      <Modal
        title="Create Team Workspace"
        placeholder="Workspace Name"
        buttonText="Create Workspace"
        visible={isOpen}
        onSubmit={(formInput) => {
          createWorkspace(formInput.workspaceName);
          telemetry.track("workspace.create", {
            value1Type: "Location",
            value1: "Typical",
            value2Type: "App Context",
            value2: isInsideMeeting ? "Meeting" : "Channel",
          });
        }}
        onClose={() => setIsOpen(false)}
      />
      <div className="py-2 overflow-x-auto px-2">
        {!isInsideMeeting && (
          <Link
            to="/configure"
            className="flex space-x-2 items-center text-creately-theme-primary dark:text-creately-theme-black text-sm font-semibold hover:underline hover:text-green-500 duration-150 transition-colors"
          >
            <ChevronLeftIcon className="w-4 h-5" />
            <span>Back</span>
          </Link>
        )}
        <p className="text-sm py-4 text-creately-theme-charade dark:text-creately-theme-black tracking-wide">
          Communicate and collaborate in Creately. To pin a workspace in your
          channel, select an existing workspace or create a new workspace.
        </p>
        <div className="rounded-md w-full bg-gray-50 dark:bg-creately-theme-dark px-3 py-2">
          <WorkspaceSearch
            gqlQuery={FIND_ORG_WORKSPACES}
            filterFn={(wksp) => ["team", "teamLink"].includes(wksp.privacy)}
          >
            {({ workspaces: searchResults, clear }) =>
              searchResults.map((wksp) => (
                <SearchItem item={wksp} key={wksp.id}>
                  <button
                    onClick={() => {
                      setWorkspaceId(wksp.id);
                      clear();
                    }}
                    className="text-sm font-semibold text-white bg-creately-theme-primary hover:bg-opacity-90 transition-colors rounded py-1 px-2 shadow"
                  >
                    Select Workspace
                  </button>
                </SearchItem>
              ))
            }
          </WorkspaceSearch>
        </div>

        <BrowserView>
          <div className="justify-between py-4">
            <button
              className="flex text-creately-theme-primary items-center bg-creately-theme-primary bg-opacity-10 border border-creately-theme-primary p-3 rounded w-full"
              onClick={() => setIsOpen(true)}
            >
              <PlusIcon className="w-6 h-6" />

              <div className="flex flex-col items-start px-6">
                <h4 className="text-sm font-semibold text-creately-theme-primary">
                  Create a Workspace
                </h4>
                <p className="text-gray-400 text-xs">
                  Blank, Toolsets, Templates
                </p>
              </div>
            </button>
          </div>
        </BrowserView>

        {loading ? <Spinner text="Loading Workspaces" /> : null}

        {teamWorkspaces ? (
          <RadioGroup value={workspaceId} onChange={setWorkspaceId}>
            <div className="grid gap-4 grid-cols-1 xs:grid-cols-2">
              {teamWorkspaces.map((doc) => (
                <RadioGroup.Option value={doc.id} key={doc.id}>
                  {({ checked }) => (
                    <WorkspaceCard workspace={doc} selected={checked} />
                  )}
                </RadioGroup.Option>
              ))}
            </div>
          </RadioGroup>
        ) : null}
      </div>
    </Layout>
  );
}
