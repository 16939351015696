import { Menu, Transition } from "@headlessui/react";
import { SearchIcon } from "@heroicons/react/outline";
import React, { Fragment, useMemo, useState } from "react";
import { Children } from "react";

export default function ListFilter({ listItems = [], children }) {
  const [searchStr, setSearchStr] = useState("");

  const filteredItems = useMemo(() => {
    return listItems.filter((item) =>
      new RegExp(`${searchStr}`, "ig").test(item.name)
    );
  }, [searchStr, listItems]);

  const isSearchValid = useMemo(() => searchStr.length !== 0, [searchStr]);

  function renderResults(children) {
    if (filteredItems.length === 0)
      return (
        <p className="text-sm text-center font-bold text-creately-theme-charade bg-gray-50 hover:bg-gray-100 dark:bg-creately-theme-dark dark:text-creately-theme-black dark:border-none p-1 rounded-sm border border-gray-100">
          No Folders Found
        </p>
      );
    return Children.map(
      children({ filteredItems, clear: () => setSearchStr("") }),
      (childNode, idx) => <Menu.Item key={idx}>{childNode}</Menu.Item>
    );
  }

  return (
    <Menu as="div" className="relative">
      <div className="flex items-center space-x-2">
        <SearchIcon className="w-4 h-4 dark:text-creately-theme-black" />
        <input
          type="text"
          className="flex-grow bg-transparent text-sm dark:bg-creately-theme-dark dark:text-creately-theme-black outline-none focus:outline-none"
          placeholder="Search Workspaces"
          value={searchStr}
          onChange={(evt) => setSearchStr(evt.target.value)}
        />
      </div>

      <Transition
        as={Fragment}
        show={isSearchValid}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items
          className="absolute top-full left-0 w-full shadow mt-2 z-10"
          static
        >
          <div className="flex flex-col space-y-2 p-2 bg-white dark:bg-creately-theme-darker rounded-md">
            {renderResults(children)}
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
}
