function Layout({ children, bgClass = "bg-creately-theme-secondary" }) {
  return (
    <>
      <main className={`font-sans antialiased min-h-screen ${bgClass} dark:bg-creately-theme-darker`}>
        {children}
      </main>
    </>
  );
}

export default Layout;
