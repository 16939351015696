import { useMutation, useQuery } from "@apollo/client";
import { RadioGroup } from "@headlessui/react";
import { ChevronLeftIcon, FolderIcon } from "@heroicons/react/outline";
import { useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import FolderCard from "../components/FolderCard";
import Modal from "../components/Modal";
import Spinner from "../components/Spinner";
import { settings as teamsConfig } from "@microsoft/teams-js";
import { getAppUrl } from "../helpers/utils";
import {
  CREATE_ORG_FOLDER,
  GET_ORG_FOLDERS,
  SET_ORG_FOLDER_PRIVACY,
} from "../queries/configure";
import ListFilter from "../components/ListFilter";
import Layout from "../common/app.layout";
import { useTeams } from "../hooks/useTeams";
import { BrowserView } from "react-device-detect";
import { useTelemetry } from "../contexts/tacker.context";

export default function ConfigureFolderPage() {
  let [isOpen, setIsOpen] = useState(false);
  const [folderId, setFolderId] = useState("");
  const [{ teamsContext }] = useTeams();
  const { telemetry } = useTelemetry();

  const { loading, data: orgFoldersResponse } = useQuery(GET_ORG_FOLDERS);

  const folderInfo = useMemo(() => {
    if (!folderId || !orgFoldersResponse) return null;
    return orgFoldersResponse.organization.folders.find(
      (doc) => doc.id === folderId
    );
  }, [folderId, orgFoldersResponse]);

  const [folderCreate] = useMutation(CREATE_ORG_FOLDER);
  const [setFolderPrivacy] = useMutation(SET_ORG_FOLDER_PRIVACY);

  async function createFolder(folderName) {
    const {
      data: { folderCreate: document },
    } = await folderCreate({
      variables: {
        folderName,
      },
      refetchQueries: [GET_ORG_FOLDERS],
      onCompleted: () => setIsOpen(false),
    });
    await setFolderPrivacy({ variables: { folderId: document.id } });
    setFolderId(document.id);
  }

  useEffect(() => {
    teamsConfig.registerOnSaveHandler(function (saveEvent) {
      var tabUrl = getAppUrl(`/channel/${folderId}`);
      teamsConfig.setSettings({
        contentUrl: tabUrl, // Mandatory parameter
        entityId: tabUrl, // Mandatory parameter
        suggestedDisplayName: folderInfo?.name || "Creately Diagram",
      });
      telemetry.track("workspace.channel.pin", {
        value1Type: "Pinned Location",
        value1: "Folder",
      });
      saveEvent.notifySuccess();
    });

    teamsConfig.setValidityState(!!folderId);
  }, [folderId, folderInfo, telemetry]);

  useEffect(() => {
    if (!teamsContext) return;
    if (
      teamsContext.theme === "dark" ||
      window.matchMedia("(prefers-color-scheme: dark)").matches
    ) {
      document.documentElement.classList.add("dark");
    }

    return () => document.documentElement.classList.remove("dark");
  }, [teamsContext]);

  return (
    <Layout bgClass="bg-white dark:bg-transparent">
      <Modal
        title="Create Team Folder"
        placeholder="Folder Name"
        buttonText="Create Folder"
        visible={isOpen}
        onSubmit={(formInput) => createFolder(formInput.workspaceName)}
        onClose={() => setIsOpen(false)}
      />

      <div className="py-2 overflow-x-auto px-2 h-screen">
        <Link
          to="/configure"
          className="flex space-x-2 items-center text-creately-theme-primary dark:text-creately-theme-black text-sm font-semibold hover:underline hover:text-green-500 duration-150 transition-colors"
        >
          <ChevronLeftIcon className="w-4 h-5" />
          <span>Back</span>
        </Link>
        <p className="text-sm py-4 text-creately-theme-charade dark:text-creately-theme-black tracking-wide">
          Communicate and collaborate in Creately. To pin a folder in your
          channel, select an existing folder or create a new folder.
        </p>
        {orgFoldersResponse ? (
          <div className="rounded-md w-full bg-gray-50 dark:bg-creately-theme-dark px-3 py-2">
            <ListFilter listItems={orgFoldersResponse.organization.folders}>
              {({ filteredItems: searchResults, clear }) =>
                searchResults.map((folder) => (
                  <div className="group flex items-start justify-between bg-gray-50 hover:bg-gray-100 dark:bg-creately-theme-dark dark:hover:bg-creately-theme-charade dark:border-none px-1 py-2 rounded-md border border-gray-100">
                    <div className="flex space-x-2">
                      <FolderIcon className="w-6 h-6 dark:text-creately-theme-black" />
                      <h5 className="text-gray-900 dark:text-gray-300 dark:text-opacity-70 font-semibold tracking-tight">
                        {folder.name}
                      </h5>
                    </div>
                    <div className="hidden self-start group-hover:block">
                      <button
                        onClick={() => {
                          setFolderId(folder.id);
                          clear();
                        }}
                        className="text-sm font-semibold text-white bg-creately-theme-primary hover:bg-opacity-90 transition-colors rounded py-1 px-2 shadow"
                      >
                        Select Folder
                      </button>
                    </div>
                  </div>
                ))
              }
            </ListFilter>
          </div>
        ) : null}

        <BrowserView>
          <div className="justify-between py-4">
            <button
              className="flex text-creately-theme-primary items-center bg-creately-theme-primary bg-opacity-10 border border-creately-theme-primary p-3 rounded w-full"
              onClick={() => setIsOpen(true)}
            >
              <span className="text-lg">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M12 4v16m8-8H4"
                  />
                </svg>
              </span>

              <div className="flex flex-col items-start px-6">
                <h4 className="text-sm font-semibold text-creately-theme-primary">
                  Create a Folder
                </h4>
                <p className="text-gray-400 text-xs">
                  Organize your team workspaces into a folder
                </p>
              </div>
            </button>
          </div>
        </BrowserView>

        {loading ? <Spinner text="Loading Workspaces" /> : null}

        {orgFoldersResponse ? (
          <RadioGroup value={folderId} onChange={setFolderId}>
            <div className="grid gap-4 grid-cols-1 xs:grid-cols-2">
              {orgFoldersResponse.organization.folders.map((folder) => (
                <RadioGroup.Option value={folder.id} key={folder.id}>
                  {({ checked }) => (
                    <FolderCard folder={folder} selected={checked} />
                  )}
                </RadioGroup.Option>
              ))}
            </div>
          </RadioGroup>
        ) : null}
      </div>
    </Layout>
  );
}
