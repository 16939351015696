import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import ApplicationEditorPage from "./app/editor.page";
import ChannelIndexPage from "./channel/index.page";
import NotFoundPage from "./common/404.page";
import HomePage from "./common/home.page";
import LoginPage from "./common/login.page";
import ProtectedRoute from "./common/protected.route";
import ConfigurationIndexPage from "./configure/index.page";
import { AuthProvider } from "./contexts/auth.context";
import { TelemetryProvider } from "./contexts/tacker.context";
import PersonalIndexPage from "./personal/index.page";
import { GQLClientProvider } from "./helpers/gqlClientHelper";
import HelpPage from "./common/help.page";
import TourPage from "./common/tour.page";
import { useTeams } from "./hooks/useTeams";
import classNames from "classnames";

function App() {
  const [{ appTheme }] = useTeams();

  return (
    <div
      className={classNames({ dark: ["dark", "contrast"].includes(appTheme) })}
    >
      <AuthProvider>
        <TelemetryProvider>
          <GQLClientProvider>
            <Router>
              <Switch>
                <Route exact path="/" component={HomePage} />
                <Route exact path="/login" component={LoginPage} />
                <Route exact path="/help" component={HelpPage} />
                <Route exact path="/tour" component={TourPage} />
                <ProtectedRoute
                  path="/personal"
                  component={PersonalIndexPage}
                />
                <ProtectedRoute
                  path="/channel/:folderId"
                  component={ChannelIndexPage}
                />
                <ProtectedRoute
                  path="/configure"
                  component={ConfigurationIndexPage}
                />
                <ProtectedRoute
                  path="/app/:workspaceId"
                  component={ApplicationEditorPage}
                />
                <Route path="*" component={NotFoundPage} />
              </Switch>
            </Router>
          </GQLClientProvider>
        </TelemetryProvider>
      </AuthProvider>
    </div>
  );
}

export default App;
