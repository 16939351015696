import * as microsoftTeams from "@microsoft/teams-js";

export const getClientToken = () =>
  new Promise((resolve, reject) => {
    microsoftTeams.authentication.getAuthToken({
      successCallback: resolve,
      failureCallback: reject,
    });
  });

export const exchangeToken = async (authResult, tenantId) => {
  const tokenEndpoint = `https://login.microsoftonline.com/${tenantId}/oauth2/v2.0/token`;
  const formData = new URLSearchParams();
  formData.set("code", authResult.code);
  formData.set("code_verifier", authResult.codeVerifier);
  formData.set("redirect_uri", authResult.redirectUri);
  formData.set("client_id", process.env.REACT_APP_AZURE_CLIENT_ID);
  formData.set("scope", "User.Read openid email https://graph.microsoft.com/User.Read.All");
  formData.set("grant_type", "authorization_code");
  const authResponse = await fetch(tokenEndpoint, {
    method: "POST",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded;charset=utf-8;",
    },
    body: formData,
  });
  if (!authResponse.ok)
    throw new Error("Failed to Authenticate - ", authResponse.statusText);
  const tokenResponse = await authResponse.json();
  return tokenResponse["access_token"];
};

export const requestConsent = (tenantId) => {
  return new Promise((resolve, reject) => {
    const scopes = ["User.Read", "openid", "email", "https://graph.microsoft.com/User.Read.All"];
    microsoftTeams.authentication.authenticate({
      url: `${window.location.origin}/auth-start.html?clientId=${
        process.env.REACT_APP_AZURE_CLIENT_ID
      }&scope=${encodeURIComponent(scopes.join(" "))}`,
      width: 600,
      height: 535,
      successCallback: (authResult) => {
        exchangeToken(authResult, tenantId).then(resolve);
      },
      failureCallback: (reason) => {
        reject(JSON.stringify(reason));
      },
    });
  });
};

export const TEAMS_WINDOW_CONTEXT = {
  config: {
    group: "config.group",
    channel: "config.channel",
    meeting: "config.meeting",
  },
  tab: {
    personal: "tab.personal",
    group: "tab.group",
    channel: "tab.channel",
    meeting: "tab.meeting",
  },
  meeting: { sidepanel: "meeting.sidepanel", stage: "meeting.stage" },
};

export const getTeamsWindowContext = (teamsContext) => {
  if (!teamsContext) return "unknown";

  const { frameContext, entityId, chatId, meetingId, channelId } = teamsContext;
  if (frameContext === "settings") {
    if (chatId && meetingId) {
      // log({ tag: "WHERE", body: ">>> CONFIGURATION PAGE[MEETING]" });
      return TEAMS_WINDOW_CONTEXT.config.meeting;
    } else if (channelId) {
      // log({ tag: "WHERE", body: ">>> CONFIGURATION PAGE[CHANNEL]" });
      return TEAMS_WINDOW_CONTEXT.config.channel;
    } else {
      // log({ tag: "WHERE", body: ">>> CONFIGURATION PAGE[GROUP]" });
      return TEAMS_WINDOW_CONTEXT.config.group;
    }
  } else if (frameContext === "sidePanel") {
    // log({ tag: "WHERE", body: ">>> METTING SIDE PANEL" });
    return TEAMS_WINDOW_CONTEXT.meeting.sidepanel;
  } else if (frameContext === "meetingStage") {
    // log({ tag: "WHERE", body: ">>> MEETING STAGE" });
    return TEAMS_WINDOW_CONTEXT.meeting.stage;
  } else if (entityId === "com.cinergix.creately.personal") {
    // log({ tag: "WHERE", body: ">>> PERSONAL TAB" });
    return TEAMS_WINDOW_CONTEXT.tab.personal;
  } else if (frameContext === "content") {
    if (meetingId && chatId) {
      // log({ tag: "WHERE", body: ">>> MEETING DETAILS PAGE" });
      return TEAMS_WINDOW_CONTEXT.tab.meeting;
    } else if (chatId) {
      // log({ tag: "WHERE", body: ">>> GROUP CHAT PAGE" });
      return TEAMS_WINDOW_CONTEXT.tab.group;
    } else if (channelId) {
      // log({ tag: "WHERE", body: ">>> CHANNEL PAGE" });
      return TEAMS_WINDOW_CONTEXT.tab.channel;
    }
  }
};
