import { gql } from "@apollo/client";

export const GET_ORG_WORKSPACES = gql`
  query OrgWorkspacesQuery {
    organization {
      documents {
        id
        name
        description
        type
        privacy
        status
        lastUpdated
        collaborators {
          user {
            id
            role
          }
        }
      }
    }
  }
`;

export const CREATE_ORG_WORKSPACE = gql`
  mutation CreateOrgWorkspace($documentInput: DocumentInput!) {
    documentCreateWithPrivacyLevel(
      document: $documentInput
      privacy: teamLink
      private: true
    ) {
      id
      name
    }
  }
`;

export const GET_ORG_FOLDERS = gql`
  query OrgFoldersQuery {
    organization {
      folders {
        id
        name
        documents {
          name
        }
      }
    }
  }
`;

export const CREATE_ORG_FOLDER = gql`
  mutation CreateOrgFolder($folderName: String!) {
    folderCreate(name: $folderName) {
      id
      name
    }
  }
`;

export const SET_ORG_FOLDER_PRIVACY = gql`
  mutation SetFolderPrivacy($folderId: String!) {
    folderSetPrivacy(id: $folderId, privacy: team)
  }
`;

export const FIND_ORG_WORKSPACES = gql`
  query FindWorkspace($name: String!) {
    searchDocument(name: $name) {
      id
      name
      privacy
      status
      lastUpdated
    }
  }
`;

export const FIND_ORG_FOLDERS = gql`
  query FindFolder($name: String!) {
    searchDocument(name: $name) {
      id
      name
      lastUpdated
    }
  }
`;
