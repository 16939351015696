import { gql } from "@apollo/client";

export const GET_FOLDER_DETAILS = gql`
  query FolderDetails($folderId: String!) {
    folder(id: $folderId) {
      id
      name
      documents {
        id
        name
      }
    }
  }
`;

export const CREATE_TEAM_WORKSPACE = gql`
  mutation CreateTeamWorkspace($documentInput: DocumentInput!) {
    documentCreateWithPrivacyLevel(
      document: $documentInput
      source: { type: creately }
      private: true
      privacy: team
    ) {
      id
      name
    }
  }
`;

export const GET_RECENT_TEAM_WORKSPACES = gql`
  query RecentTeamWorkspaces($folderId: String!) {
    folder(id: $folderId) {
      id
      name
      documents {
        id
        name
        description
        type
        status
        lastUpdated
        collaborators {
          user {
            firstName
            avatar {
              url
              filename
            }
            email
          }
        }
      }
    }
  }
`;

export const GET_ALL_WORKSPACES = gql`
  query TeamWorkspaces($folderId: String!) {
    folder(id: $folderId) {
      id
      documents {
        id
        name
        description
        type
        status
        lastUpdated
        image {
          svg
        }
        collaborators {
          user {
            firstName
            avatar {
              url
              filename
            }
            email
          }
        }
      }
    }
  }
`;

export const GET_RECENT_UPDATES = gql`
  query RecentTeamUpdates($folderId: String!) {
    teamUpdates(folderId: $folderId) {
      updates {
        title
        author {
          name
          username
        }
        timestamp
      }
    }
  }
`;

export const GET_ALL_UPDATES = gql`
  query TeamUpdates($folderId: String!) {
    allActivities(folderId: $folderId) {
      updates {
        title
        author {
          name
          username
        }
        timestamp
      }
    }
  }
`;

export const FIND_CHANNEL_WORKSPACES = gql`
  query FindChannelWorkspace($name: String!, $folderId: String) {
    searchDocument(name: $name, type: "folder", folderId: $folderId) {
      id
      name
      lastUpdated
      status
    }
  }
`;
