import React from "react";

export default function DocumentStatusTag({ status }) {
  if (status === "none" || status === null) return null;
  return DOCUMENT_STATUS_TAGS[status];
}

const DOCUMENT_STATUS_TAGS = {
  draft: (
    <span className="bg-creately-status-draft text-gray-800 rounded-sm px-1 text-xs">
      Draft
    </span>
  ),
  "in-progress": (
    <span className="bg-creately-status-in-progress text-white rounded-sm px-1 text-xs">
      In Progress
    </span>
  ),
  "in-review": (
    <span className="bg-creately-status-in-review text-gray-800 rounded-sm px-1 text-xs">
      In Review
    </span>
  ),
  approved: (
    <span className="bg-creately-status-approved text-gray-800 rounded-sm px-1 text-xs">
      Approved
    </span>
  ),
  final: (
    <span className="bg-creately-status-final text-white rounded-sm px-1 text-xs">
      Final
    </span>
  ),
  archived: (
    <span className="bg-creately-status-archived text-gray-800 rounded-sm px-1 text-xs">
      Archived
    </span>
  ),
};
