import React from "react";

export default function TourCard({ card }) {
  if (card.videoId && card.isWistia) {
    return (
      <>
        <h2 className="text-white text-xl font-semibold tracking-wide pt-3">
          {card.heading}
        </h2>
        <h2 className="text-creately-theme-secondary tracking-wide pt-3">
          {card.description}
        </h2>

        <div
          className="wistia_responsive_padding relative mt-2"
          style={{ padding: "56.25% 0 0 0" }}
        >
          <div className="wistia_responsive_wrapper absolute top-0 left-0 h-full w-full">
            <iframe
              title="Craetely Product Tour"
              src={`https://fast.wistia.net/embed/iframe/${card.videoId}?videoFoam=true&volume=0.4&autoPlay=false&playButton=false`}
              frameBorder="0"
              scrolling="no"
              className="wistia_embed"
              name="wistia_embed"
              allowFullScreen
              allowtransparency="true"
              mozallowfullscreen="true"
              webkitallowfullscreen="true"
              oallowfullscreen="true"
              msallowfullscreen="true"
              width="100%"
              height="100%"
            ></iframe>
          </div>
        </div>
      </>
    );
  } else if (card.videoId) {
    return (
      <div className="flex flex-col">
        <h2 className="text-white text-xl font-semibold tracking-wide pt-3">
          {card.heading}
        </h2>
        <h2 className="text-creately-theme-secondary tracking-wide pt-3">
          {card.description}
        </h2>
        <video
          className="self-center mt-4"
          width="570"
          autoPlay={true}
          loop={true}
          src={card.videoId}
        >
          Your browser does not support the video tag.
        </video>
      </div>
    );
  }

  return (
    <div className="flex flex-col">
      <h2 className="text-white text-xl font-semibold tracking-wide pt-3">
        {card.heading}
      </h2>
      <h2 className="text-creately-theme-secondary tracking-wide pt-3">
        {card.description}
      </h2>
      <img className="self-center my-4" src={card.image} alt={card.heading} />
    </div>
  );
}
