import { useContext, createContext } from "react";
import { createTelemetry } from "../helpers/tracking";

const EVENT_TRACKER_CONFIG = [{ name: "amplitude" }];

const TelemetryContext = createContext({ telemetry: null });

const TelemetryProvider = (props) => {
  return (
    <TelemetryContext.Provider
      value={{
        telemetry: createTelemetry(EVENT_TRACKER_CONFIG),
      }}
      {...props}
    />
  );
};

const useTelemetry = () => useContext(TelemetryContext);

export { TelemetryProvider, useTelemetry };
