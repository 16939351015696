import { useEffect, useState } from "react";
import { Redirect, useLocation } from "react-router-dom";
import * as microsoftTeams from "@microsoft/teams-js";
import { useAuth } from "../contexts/auth.context";
import { useTeams } from "../hooks/useTeams";
import {
  getClientToken,
  getTeamsWindowContext,
  requestConsent,
  TEAMS_WINDOW_CONTEXT,
} from "../helpers/teamsHelper";
import Layout from "./app.layout";
import { YoutubeEmbed } from "../components/YTEmbedPlayer";
import { useTelemetry } from "../contexts/tacker.context";

export default function LoginPage(props) {
  const { state } = useLocation();
  const [redirectToReferrer, setRedirectToReferrer] = useState(false);
  const { login, loggedIn } = useAuth();
  const [{ teamsContext, isInitializing }] = useTeams();
  const [showSpinner, setShowSpinner] = useState(false);
  const { telemetry } = useTelemetry();

  useEffect(() => {
    if (isInitializing) return;
    telemetry.track("app.load", {
      value1Type: "Visit",
      value1: teamsContext.tid,
      value2Type: "Target",
      value2: teamsContext.upn,
    });
  }, [telemetry, teamsContext, isInitializing]);

  async function handleLogin() {
    const tabContext = getTeamsWindowContext(teamsContext);
    if (tabContext === TEAMS_WINDOW_CONTEXT.tab.meeting) {
      gotoDeepLink({ subEntityId: teamsContext.meetingId });
      return;
    }
    try {
      setShowSpinner(true);
      const teamsToken = await getClientToken();
      await login(teamsContext.tid, teamsToken)
        .catch((error) => {
          if (error.errorCode === 403) {
            return askConsent();
          }
          telemetry.track("signin.fail", {
            value1Type: "Fail Reason",
            value1: error.message,
          });
          throw error;
        })
        .then((userInfo) => {
          telemetry.identify(userInfo.id, {
            value1Type: "Plan ID",
            value1: userInfo.organization.id,
          });
          telemetry.track("signin.success");
          setRedirectToReferrer(true);
        });
    } catch (error) {
      if (error === "invalid_grant") {
        const teamsToken = await requestConsent(teamsContext.tid).then(
          getClientToken
        );
        await login(teamsContext.tid, teamsToken)
          .catch((error) => {
            if (error.errorCode === 403) {
              return askConsent();
            }
            telemetry.track("signin.fail", {
              value1Type: "Fail Reason",
              value1: error.message,
            });
            return Promise.reject(error);
          })
          .then((userInfo) => {
            telemetry.identify(userInfo.id, {
              value1Type: "Plan ID",
              value1: userInfo.organization.id,
            });
            telemetry.track("signin.success");
            setRedirectToReferrer(true);
          });
      }
    } finally {
      setShowSpinner(false);
    }
  }

  function gotoDeepLink(
    context,
    entityId = "com.cinergix.creately.personal",
    webUrl = "https://cgx-msteams.ngrok.io"
  ) {
    var encodedWebUrl = encodeURI(webUrl);
    var encodedContext = encodeURI(JSON.stringify(context));
    var taskItemUrl = `https://teams.microsoft.com/l/entity/5350df88-593e-4aa6-a05c-5675edf4177e/${entityId}/?webUrl=${encodedWebUrl}&context=${encodedContext}`;

    microsoftTeams.executeDeepLink(taskItemUrl);
  }

  function askConsent() {
    return new Promise((resolve, reject) => {
      const scopes = ["https://graph.microsoft.com/User.Read"];
      microsoftTeams.authentication.authenticate({
        url:
          window.location.origin +
          `/auth-start.html?clientId=${
            process.env.REACT_APP_AZURE_CLIENT_ID
          }&scope=${encodeURIComponent(scopes.join(" "))}`,
        width: 600,
        height: 535,
        successCallback: resolve,
        failureCallback: reject,
      });
    });
  }

  if (isInitializing) {
    return <h1>Loading App...</h1>;
  }

  if (redirectToReferrer || loggedIn) {
    return <Redirect to={state?.from || "/"} />;
  }

  return (
    <Layout bgClass="bg-white">
      <div className="flex flex-col space-y-10 items-center px-2 sm:px-0 sm:w-3/5 xl:w-1/3 h-screen mx-auto pt-8">
        <h2 className="text-center dark:text-white text-lg font-semibold dark:font-normal tracking-wide pt-2">
          Welcome to Creately! We&lsquo;re glad you&lsquo;re here.
        </h2>
        <h2 className="text-center text-sm text-creately-theme-header dark:text-gray-400 lg:font-bold dark:font-normal tracking-wide pt-1">
          An infinite visual canvas to bring your strategy and business plans
          together, share them, monitor progress, and drive innovation.
        </h2>
        <YoutubeEmbed embedId="9CyRNoGLDc8" />
        <p className="text-creately-status-in-progress dark:text-gray-500 text-center text-sm">
          Develop your strategic plan and track it all the way to completion.
          Create clarity, alignment, and organization-wide engagement.
        </p>
        <button
          className="text-sm md:text-base flex items-center space-x-3 rounded bg-creately-theme-primary text-white font-semibold tracking-wide px-4 md:px-3 py-2 shadow-md hover:bg-creately-theme-primary hover:bg-opacity-90"
          onClick={handleLogin}
        >
          {showSpinner && (
            <span className="inline-block w-4 h-4 border-2 border-creately-theme-skin spinner"></span>
          )}
          <span>Get Started</span>
        </button>
      </div>
    </Layout>
  );
}
