export default function Spinner({ text }) {
  return (
    <div className="flex flex-col items-center mt-4">
      <div className="w-8 h-8 border-2 border-creately-theme-skin spinner"></div>
      <h4 className="text-center text-sm mt-6 text-creately-status-in-progress dark:text-creately-theme-black">
        {text}
      </h4>
    </div>
  );
}
