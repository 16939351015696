import { formatDistanceToNow } from "date-fns";
import pluralize from "pluralize";
import { executeDeepLink } from "@microsoft/teams-js";

export const dateSince = (timestampString) => {
  if (!timestampString)
    return formatDistanceToNow(new Date(), { addSuffix: true });
  return formatDistanceToNow(Number(timestampString), { addSuffix: true });
};

export const getAppUrl = (path) => {
  return window.location.protocol + "//" + window.location.host + path;
};

export const getInitials = (fullName) => {
  const [firstName, lastName] = fullName.split(" ");

  if (firstName && lastName) {
    return `${firstName.charAt(0)}${lastName.charAt(0)}`.toUpperCase();
  }

  return firstName.charAt(0).toUpperCase();
};

export const getPlural = (count, word) => {
  return pluralize(word, count, true);
};

export function gotoDeepLink(
  context,
  entityId = "com.cinergix.creately.personal",
  webUrl = "https://cgx-msteams.ngrok.io"
) {
  var encodedWebUrl = encodeURI(webUrl);
  var encodedContext = encodeURI(JSON.stringify(context));
  var taskItemUrl = `https://teams.microsoft.com/l/entity/5350df88-593e-4aa6-a05c-5675edf4177e/${entityId}/?webUrl=${encodedWebUrl}&context=${encodedContext}`;

  executeDeepLink(taskItemUrl);
}
