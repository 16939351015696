import { gql } from "@apollo/client";

export const GET_PERSONAL_WORKSPACES = gql`
  query RecentDiagramsQuery {
    user {
      documents(filter: recent) {
        id
        name
        description
        type
        lastUpdated
        privacy
        status
        image {
          svg
        }
        collaborators {
          user {
            id
            role
            email
          }
        }
      }
    }
  }
`;

export const GET_ALL_PERSONAL_WORKSPACES = gql`
  query RecentDiagramsQuery {
    user {
      organization {
        documents {
          id
          name
          lastUpdated
          privacy
          status
          image {
            svg
          }
          collaborators {
            user {
              id
              role
            }
          }
        }
      }
    }
  }
`;

export const CREATE_PRIVATE_WORKSPACE = gql`
  mutation CreateTeamWorkspace(
    $documentInput: DocumentInput!
    $privacyLevel: DocumentPrivacy!
  ) {
    documentCreateWithPrivacyLevel(
      document: $documentInput
      source: { type: creately }
      private: true
      privacy: $privacyLevel
    ) {
      id
      name
    }
  }
`;

export const GET_PERSONAL_SHARED_WORKSPACES = gql`
  query SharedDiagramsQuery {
    user {
      documents(filter: shared) {
        id
        name
        description
        lastUpdated
        privacy
        status
        image {
          svg
        }
        collaborators {
          user {
            id
            role
            email
          }
        }
      }
    }
  }
`;

export const FIND_PERSONAL_WORKSPACES = gql`
  query FindWorkspace($name: String!) {
    searchDocument(name: $name) {
      id
      name
      lastUpdated
      privacy
      status
      collaborators {
        user {
          id
          role
        }
      }
    }
  }
`;
