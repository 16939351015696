import { useLazyQuery } from "@apollo/client";
import { get } from "lodash";
import { useEffect, useState } from "react";
import { FIND_CHANNEL_WORKSPACES } from "../queries/channel";

export const useFolderSearch = (
  folderId,
  { validity: isValid, filter: searchStr }
) => {
  const [isOpen, setIsOpen] = useState(false);
  const [findWorkspaces, { loading, data: foundWorkspaces }] = useLazyQuery(
    FIND_CHANNEL_WORKSPACES,
    { fetchPolicy: "cache-first" }
  );

  useEffect(() => {
    if (isValid) {
      findWorkspaces({ variables: { name: searchStr.trim(), folderId } });
      setIsOpen(true);
    } else {
      setIsOpen(false);
    }
  }, [isValid, searchStr, findWorkspaces, folderId]);

  return {
    hasSugessions: get(foundWorkspaces, "searchDocument", []).length > 0,
    isLoading: loading,
    isOpen,
    workspaces: get(foundWorkspaces, "searchDocument", []),
  };
};
