export default function FolderThumbnail(props) {
  const { folder } = props;
  return (
    <div className="w-full">
      <div className="bg-white dark:bg-creately-theme-dark dark:border-none shadow-md border border-gray-200 rounded overflow-hidden pb-3">
        <div className="relative px-3 pb-4 pt-6">
          <div className="flex items-center justify-center">
            <img
              src="/images/folder-thumbnail.png"
              alt="Diagram"
              className="block w-32"
            />
          </div>
        </div>
        <div className="flex items-center">
          <h5 className="text-sm px-4 text-gray-900 dark:text-gray-400 font-semibold tracking-tight">
            {folder.name || "Untitled Folder"}
          </h5>
        </div>
        {/* <div className="flex items-center justify-between px-4">
          <div className="flex items-center">
            <h6 className="flex items-center text-gray-400">
              <span className="text-xs font-medium">
                {dateSince(folder.lastUpdated)}
              </span>
            </h6>
          </div>
          <div className="flex flex-row-reverse justify-center">
            <div className="flex relative w-7 h-7 bg-creately-theme-secondary justify-center items-center -ml-3 rounded-full border-r-2 border-white text-xs text-creately-theme-charade">
              +5
            </div>
            <div className="flex relative w-7 h-7 justify-center items-center -ml-3 rounded-full border-r-2 border-white">
              <img
                className="rounded-full"
                alt="A"
                src="https://randomuser.me/api/portraits/men/56.jpg"
              />
            </div>
            <div className="flex relative w-7 h-7 justify-center items-center -ml-3 rounded-full border-r-2 border-white">
              <img
                className="rounded-full"
                alt="A"
                src="https://randomuser.me/api/portraits/women/68.jpg"
              />
            </div>
          </div>
        </div> */}
      </div>
    </div>
  );
}
