import * as microsoftTeams from "@microsoft/teams-js";
import { useState, useEffect } from "react";
import { unstable_batchedUpdates as batchedUpdates } from "react-dom";
import {
  ACTIVE_USER_ID_KEY,
  CURRENT_USER_ID_KEY,
} from "../contexts/auth.context";

export const checkIfInTeams = () => {
  const microsoftTeamsLib = microsoftTeams || window["microsoftTeams"];

  if (!microsoftTeamsLib) {
    return false; // the Microsoft Teams library is for some reason not loaded
  }

  if (
    (window.parent === window.self && window.nativeInterface) ||
    window.navigator.userAgent.includes("Teams/") ||
    window.name === "embedded-page-container" ||
    window.name === "extension-tab-frame"
  ) {
    return true;
  }
  return false;
};

export const useTeamsAppInit = () => {
  microsoftTeams.initialize(microsoftTeams.appInitialization.notifySuccess);
};

export const useTeams = () => {
  const [insideTeams, setInsideTeams] = useState(undefined);
  const [teamsContext, setTeamsContext] = useState(undefined);
  const [fullScreen, setFullScreen] = useState(undefined);
  const [appTheme, setAppTheme] = useState("default");
  const [isInitializing, setIsInitializing] = useState(true);

  useEffect(() => {
    const isInsideTeams = checkIfInTeams();
    if (isInsideTeams) {
      microsoftTeams.initialize(() => {
        microsoftTeams.getContext((context) => {
          setUserKeys(context)
          batchedUpdates(() => {
            setInsideTeams(true);
            setTeamsContext(context);
            setFullScreen(context.isFullScreen);
            setAppTheme(context.theme);
            setIsInitializing(false);
          });
        });
        microsoftTeams.registerFullScreenHandler((isFullScreen) => {
          setFullScreen(isFullScreen);
        });
        microsoftTeams.registerOnThemeChangeHandler((theme) => {
          setAppTheme(theme);
        });
        microsoftTeams.appInitialization.notifySuccess();
      });
    } else {
      setInsideTeams(false);
      setIsInitializing(false);
      microsoftTeams.initialize();
    }
  }, []);

  function setUserKeys(context) {
    const previousUser = localStorage.getItem(ACTIVE_USER_ID_KEY);
    localStorage.setItem(CURRENT_USER_ID_KEY, context.userObjectId);
    if (!previousUser) {
      localStorage.setItem(ACTIVE_USER_ID_KEY, context.userObjectId);
    }
  }

  return [
    {
      insideTeams,
      teamsContext,
      isInitializing,
      fullScreen,
      appTheme,
    },
  ];
};
