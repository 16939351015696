import { useQuery } from "@apollo/client";
import { get, orderBy } from "lodash";
import { useCallback, useMemo } from "react";
import Spinner from "../components/Spinner";
import WorkspaceThumbnailList from "../components/WorkspaceThumbnailList";
import {
  FIND_PERSONAL_WORKSPACES,
  GET_PERSONAL_SHARED_WORKSPACES,
} from "../queries/personal";
import { useAuth } from "../contexts/auth.context";
import SearchItem from "../components/SearchItem";
import { Link } from "react-router-dom";
import WorkspaceSearch from "../components/WorkspaceSearch";
import { ChevronLeftIcon } from "@heroicons/react/solid";

function PersonalSharedPage() {
  const { user } = useAuth();
  const { loading, data: sharedDiagramsResponse } = useQuery(
    GET_PERSONAL_SHARED_WORKSPACES,
    { fetchPolicy: "no-cache" }
  );

  const sharedWkspFilterFn = useCallback(
    (wksp) =>
      wksp.collaborators?.length > 1 &&
      wksp.collaborators.filter(
        (collab) =>
          collab.user.id === user.id &&
          (collab.user.role === "5" || collab.user.role === "10")
      ).length > 0,
    [user]
  );

  const sharedWorkspaces = useMemo(() => {
    if (!sharedDiagramsResponse) return null;
    const wkspSharedByOthers = get(
      sharedDiagramsResponse,
      "user.documents"
    ).filter(sharedWkspFilterFn);
    return orderBy(wkspSharedByOthers, ["lastUpdated"], "desc");
  }, [sharedDiagramsResponse, sharedWkspFilterFn]);

  return (
    <>
      <div className="flex items-center mx-auto p-4 lg:w-4/5">
        <Link
          className="flex space-x-2 items-center text-creately-theme-primary dark:text-creately-theme-black text-sm font-semibold hover:underline hover:text-green-500 duration-150 transition-colors"
          to="/personal"
        >
          <ChevronLeftIcon className="w-4 h-4" />
          Dashboard
        </Link>
      </div>
      <div className="flex-grow bg-creately-theme-secondary dark:bg-creately-theme-darker">
        <div className="mx-auto px-4 lg:w-4/5 my-4">
          <WorkspaceSearch
            gqlQuery={FIND_PERSONAL_WORKSPACES}
            filterFn={sharedWkspFilterFn}
          >
            {({ workspaces: searchResults }) => {
              console.log(searchResults);
              return searchResults.map((wksp) => (
                <SearchItem item={wksp} key={wksp.id}>
                  <Link
                    to={{
                      pathname: `/app/${wksp.id}`,
                      state: { dashboardPath: "/personal/shared" },
                    }}
                    className="inline-block my-1"
                  >
                    <span className="text-sm font-semibold text-white bg-creately-theme-primary hover:bg-opacity-90 transition-colors rounded py-1 px-2 shadow">
                      Go to Workspace
                    </span>
                  </Link>
                </SearchItem>
              ));
            }}
          </WorkspaceSearch>
        </div>
        <div className="mx-auto px-4 lg:w-4/5 pt-8">
          <h3 className="font-semibold text-creately-theme-charade dark:text-creately-theme-secondary dark:text-opacity-70 mb-4">
            Workspaces shared with me
          </h3>
          {loading && <Spinner text="Loading Workspaces" />}
          {sharedWorkspaces && (
            <WorkspaceThumbnailList
              dashboardPath="/personal/shared"
              workspaces={sharedWorkspaces}
              placeholderText="You have no shared workspaces yet...!"
            />
          )}
        </div>
      </div>
    </>
  );
}

export default PersonalSharedPage;
