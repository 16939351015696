import WorkspaceThumbnail from "./WorkspaceThumbnail";

export default function RightSidebar({ workspace }) {
  return (
    <>
      <div className="px-4 py-2 bg-creately-theme-primary flex items-center rounded-sm mb-2">
        <h3 className="flex-grow text-sm text-creately-theme-secondary text-right pr-2">
          Collaborate by sharing your workspace to stage
        </h3>
        <div className="flex flex-col text-creately-theme-purple items-center mx-1 pr-4">
          <svg
            viewBox="0 0 369.159 369.159"
            className="transform -rotate-12 w-12 h-12"
            fill="currentColor"
          >
            <path d="M336.689 48.802c-1.836-5.508-10.403-6.12-11.628 0-11.016 38.556-33.048 74.052-31.823 115.668 0 5.508 6.731 7.956 10.403 4.284 5.508-4.896 10.404-9.792 14.688-15.3 1.836 60.588 6.731 166.464-78.948 146.269-4.896-1.225-8.568-3.061-12.24-4.896 20.809-19.584 29.988-47.124 12.24-77.724-4.896-8.568-17.748-15.912-27.54-10.404-19.584 9.792-21.42 36.72-18.972 55.692 1.836 13.464 7.344 24.479 15.3 32.436-18.972 14.076-45.288 20.196-66.708 15.3-17.748-4.283-28.152-14.688-32.436-28.151 9.792-6.12 18.36-12.24 25.092-18.36 20.808-18.36 39.168-69.156-3.06-75.888-34.272-6.121-40.392 63.647-38.556 83.844 0 2.448.612 4.284.612 6.732-15.912 7.344-33.66 11.016-49.572 7.956C.09 277.078 3.15 202.414 29.466 179.77c3.06-2.448-1.224-7.956-4.284-5.508-35.496 26.929-31.212 83.845-1.224 113.833 18.972 18.359 47.124 12.852 71.604 1.224 16.524 46.512 82.008 42.84 121.788 14.076 22.645 14.688 55.08 14.076 78.336-1.225 47.124-31.212 37.944-102.815 35.496-153.612 8.568 10.404 17.137 20.808 26.929 30.6 3.672 3.672 11.628 1.224 11.016-4.284-4.898-44.064-17.749-85.068-32.438-126.072zM107.19 234.238c1.836-9.18 9.792-39.78 25.704-33.048 23.868 10.404 6.732 38.556-3.672 50.796-6.732 7.344-14.688 14.076-23.868 19.584-1.836-12.24-.612-25.091 1.836-37.332zm97.92 5.508c1.224-8.567 7.344-30.6 21.42-18.359 6.731 5.508 7.344 17.136 7.344 24.479 0 15.912-6.12 29.376-16.524 40.393-11.016-11.628-14.688-29.988-12.24-46.513zM320.778 130.81l-1.837 1.836c-3.672 6.732-7.956 11.628-12.852 16.524 3.06-27.54 15.912-53.244 25.092-79.56 9.792 27.54 17.748 55.692 22.032 84.456-6.732-7.956-13.464-16.524-20.196-25.092-3.671-4.284-10.403-2.448-12.239 1.836z" />
          </svg>
        </div>
      </div>
      <WorkspaceThumbnail workspace={workspace} />
    </>
  );
}
