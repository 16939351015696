import { useState, useMemo, useCallback } from "react";
import { Link, useHistory } from "react-router-dom";
import { useMutation, useQuery } from "@apollo/client";
import {
  ChevronRightIcon,
  LogoutIcon,
  PlusIcon,
} from "@heroicons/react/outline";
import { LightningBoltIcon } from "@heroicons/react/solid";
import { orderBy, get } from "lodash";
import {
  CREATE_PRIVATE_WORKSPACE,
  FIND_PERSONAL_WORKSPACES,
  GET_PERSONAL_SHARED_WORKSPACES,
  GET_PERSONAL_WORKSPACES,
} from "../queries/personal";
import Spinner from "../components/Spinner";
import Modal from "../components/Modal";
import WorkspaceCardList from "../components/WorkspaceCardList";
import { useAuth } from "../contexts/auth.context";
import WorkspaceSearch from "../components/WorkspaceSearch";
import SearchItem from "../components/SearchItem";
import { BrowserView } from "react-device-detect";
import { useTelemetry } from "../contexts/tacker.context";

function PersonalDashboardPage() {
  const { user, logout } = useAuth();
  const [isOpen, setIsOpen] = useState(false);
  const history = useHistory();
  const { telemetry } = useTelemetry();

  const { loading: isRecentDiagramsLoading, data: recentDiagramsResponse } =
    useQuery(GET_PERSONAL_WORKSPACES, {
      errorPolicy: "ignore",
      fetchPolicy: "no-cache",
    });

  const { loading: isSharedDiagramsLoading, data: sharedDiagramsResponse } =
    useQuery(GET_PERSONAL_SHARED_WORKSPACES, {
      errorPolicy: "ignore",
      fetchPolicy: "no-cache",
    });

  const [documentCreate] = useMutation(CREATE_PRIVATE_WORKSPACE, {
    refetchQueries: [GET_PERSONAL_WORKSPACES],
    onCompleted: () => setIsOpen(false),
  });

  const recentDiagramFilterFn = useCallback(
    (wksp) => {
      if (!user) return false;
      return (
        get(wksp, "collaborators", []).filter(
          (c) => c.user.id === user.id && c.user.role === "0"
        ).length === 1
      );
    },
    [user]
  );

  const recentDiagrams = useMemo(() => {
    if (!recentDiagramsResponse) return null;
    const privateWorkspaces = get(
      recentDiagramsResponse,
      "user.documents"
    ).filter(recentDiagramFilterFn);
    return orderBy(privateWorkspaces, ["lastUpdated"], "desc").slice(0, 5);
  }, [recentDiagramsResponse, recentDiagramFilterFn]);

  const sharedWkspFilterFn = useCallback(
    (wksp) => {
      if (!user) return false;
      return (
        wksp.collaborators?.length > 1 &&
        wksp.collaborators.filter(
          (collab) =>
            collab.user.id === user.id &&
            (collab.user.role === "5" || collab.user.role === "10")
        ).length > 0
      );
    },
    [user]
  );

  const sharedWorkspaces = useMemo(() => {
    if (!sharedDiagramsResponse) return null;
    const wkspSharedByOthers = get(
      sharedDiagramsResponse,
      "user.documents"
    ).filter(sharedWkspFilterFn);
    return orderBy(wkspSharedByOthers, ["lastUpdated"], "desc").slice(0, 5);
  }, [sharedDiagramsResponse, sharedWkspFilterFn]);

  async function createWorkspace(workspaceName) {
    const {
      data: { documentCreateWithPrivacyLevel: document },
    } = await documentCreate({
      variables: {
        documentInput: { name: workspaceName, projectId: "home" },
        privacyLevel: "team",
      },
    });
    return document.id;
  }

  function handleQuickStart() {
    createWorkspace("Untitled Workspace").then((workspaceId) => {
      telemetry.track("workspace.create", {
        value1Type: "Location",
        value1: "Quick Start",
      });
      history.push({
        pathname: `/app/${workspaceId}`,
        state: { starter: true, dashboardPath: "/personal" },
      });
    });
  }

  return (
    <>
      <Modal
        title="Create Personal Workspace"
        placeholder="Your New Workspace"
        buttonText="Create Workspace"
        visible={isOpen}
        onSubmit={(formInput) => {
          createWorkspace(formInput.workspaceName).then(() =>
            telemetry.track("workspace.create", {
              value1Type: "Location",
              value1: "Typical",
            })
          );
        }}
        onClose={() => setIsOpen(false)}
      />
      <div className="flex-grow">
        <div className="flex items-center space-x-2 mx-auto px-4 lg:w-4/5 mb-10">
          <div className="flex-grow">
            <WorkspaceSearch
              gqlQuery={FIND_PERSONAL_WORKSPACES}
              filterFn={recentDiagramFilterFn}
            >
              {({ workspaces: searchResults }) =>
                searchResults.map((wksp) => (
                  <SearchItem item={wksp} key={wksp.id}>
                    <Link
                      to={{
                        pathname: `/app/${wksp.id}`,
                        state: { dashboardPath: "/personal" },
                      }}
                      className="inline-block my-1"
                    >
                      <span className="hidden sm:inline-block text-sm font-semibold text-white bg-creately-theme-primary hover:bg-opacity-90 transition-colors rounded py-1 px-2 shadow">
                        Go to Workspace
                      </span>
                      <span className="sm:hidden text-sm font-semibold text-white bg-creately-theme-primary hover:bg-opacity-90 transition-colors rounded py-1 px-2 shadow">
                        View
                      </span>
                    </Link>
                  </SearchItem>
                ))
              }
            </WorkspaceSearch>
          </div>
          <div className="flex items-center space-x-1">
            <button
              onClick={() => {
                logout(() => {
                  telemetry.track("signout.success");
                  history.replace("/login", {
                    from: history.location.pathname,
                  });
                });
              }}
              className="px-2 my-2 flex space-x-2 items-center text-gray-500 hover:text-gray-800 dark:text-creately-theme-black dark:text-opacity-75 dark:hover:text-opacity-90"
            >
              <LogoutIcon className="w-6 h-6" />
              <span className="text-sm font-semibold">Logout</span>
            </button>
          </div>
        </div>
        <BrowserView>
          <div className="mx-auto px-4 lg:w-4/5">
            <h3 className="font-semibold text-creately-theme-charade dark:text-creately-theme-secondary dark:text-opacity-70">
              {user && `Welcome ${user.name},`}
            </h3>
            <div className="flex sm:items-center space-x-2 my-2">
              <button
                className="flex flex-col items-center sm:flex-row w-full sm:w-auto text-creately-theme-orange bg-creately-theme-yellow bg-opacity-10 border border-creately-theme-orange p-3 rounded"
                onClick={handleQuickStart}
              >
                <LightningBoltIcon className="w-6 h-6" />

                <div className="flex flex-col items-center sm:items-start px-3">
                  <h4 className="text-sm font-semibold text-creately-theme-orange">
                    Quick Start
                  </h4>
                  <p className="text-gray-400 text-xs">
                    Use built-in toolkits & templates
                  </p>
                </div>
              </button>
              <button
                className="flex flex-col items-center sm:flex-row w-full sm:w-auto text-creately-theme-primary bg-creately-theme-primary bg-opacity-10 border border-creately-theme-primary p-3 rounded"
                onClick={() => setIsOpen(true)}
              >
                <PlusIcon className="w-6 h-6" />

                <div className="flex flex-col items-center sm:items-start px-3">
                  <h4 className="text-sm font-semibold text-creately-theme-primary">
                    Create a Workspace
                  </h4>
                  <p className="text-gray-400 text-xs">Start from scratch</p>
                </div>
              </button>
            </div>
          </div>
        </BrowserView>
        <div className="mx-auto px-4 lg:w-4/5 mt-6 border-t-creately-theme-charade pt-4">
          <div className="flex items-center justify-between mb-4">
            <h3 className="font-semibold text-creately-theme-charade dark:text-creately-theme-secondary dark:text-opacity-70">
              Continue your work
            </h3>
            <Link
              to="/personal/all"
              className="flex space-x-2 items-center text-creately-theme-primary dark:text-creately-theme-black text-sm font-semibold hover:underline hover:text-green-500 duration-150 transition-colors"
            >
              <span className="hidden sm:inline">All Workspaces</span>
              <span className="sm:hidden">View All</span>
              <span>
                <ChevronRightIcon className="w-4 h-4" />
              </span>
            </Link>
          </div>
          {isRecentDiagramsLoading && (
            <Spinner text="Loading Recent Workspaces" />
          )}
          {recentDiagrams && (
            <WorkspaceCardList
              workspaces={recentDiagrams}
              dashboardPath="/personal"
              placeholderText="You have no recent workspaces yet...!"
            />
          )}
        </div>
        <div className="mx-auto px-4 lg:w-4/5 mt-6 border-t-creately-theme-charade pt-4">
          <div className="flex items-center justify-between mb-4">
            <h3 className="font-semibold text-creately-theme-charade dark:text-creately-theme-secondary dark:text-opacity-70">
              Collaborate with your team
            </h3>
            <Link
              to="/personal/shared"
              className="flex space-x-2 items-center text-creately-theme-primary dark:text-creately-theme-black text-sm font-semibold hover:underline hover:text-green-500 duration-150 transition-colors"
            >
              <span className="hidden sm:inline">All Shared Workspaces</span>
              <span className="sm:hidden">View All</span>
              <span>
                <ChevronRightIcon className="w-4 h-4" />
              </span>
            </Link>
          </div>
          {isSharedDiagramsLoading && (
            <Spinner text="Loading Shared Workspaces" />
          )}
          {sharedWorkspaces && (
            <WorkspaceCardList
              workspaces={sharedWorkspaces}
              dashboardPath="/personal"
              placeholderText="You have no shared workspaces yet...!"
            />
          )}
        </div>
      </div>
    </>
  );
}

export default PersonalDashboardPage;
