import { Route, Switch, useRouteMatch, Redirect } from "react-router-dom";
import PersonalSharedPage from "./shared.page";
import PersonalDashboardPage from "./dashboard.page";
import PersonalWorkspacesPage from "./workspaces.page";
import Layout from "../common/app.layout";
import { useTeams } from "../hooks/useTeams";

function PersonalIndexPage() {
  const { path } = useRouteMatch();
  const [{ teamsContext }] = useTeams();

  if (teamsContext && teamsContext.subEntityId) {
    return (
      <Redirect
        to={{
          pathname: `/app/${teamsContext.subEntityId}`,
          state: { dashboardPath: "/personal" },
        }}
      />
    );
  }

  return (
    <Layout>
      <div className="py-6">
        <Switch>
          <Route exact path={path} component={PersonalDashboardPage} />
          <Route
            exact
            path={`${path}/all`}
            component={PersonalWorkspacesPage}
          />
          <Route exact path={`${path}/shared`} component={PersonalSharedPage} />
        </Switch>
      </div>
    </Layout>
  );
}

export default PersonalIndexPage;
