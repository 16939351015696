import { CheckIcon } from "@heroicons/react/outline";
import classnames from "classnames";
export default function FolderCard({ folder, selected }) {
  return (
    <div className="w-full relative">
      <div
        className={classnames({
          "flex items-start border shadow-md rounded overflow-hidden py-2 pb-3 pl-4": true,
          "bg-creately-theme-beige border-gray-200 dark:bg-creately-theme-dark dark:border-none": !selected,
          "bg-creately-theme-primary bg-opacity-10 border-creately-theme-primary":
            selected,
        })}
      >
        <div className="relative">
          <div className="flex items-center justify-center">
            <img
              src="/images/folder-thumbnail.png"
              alt="Diagram"
              className="block w-10"
            />
          </div>
        </div>
        <div className="flex-grow self-stretch">
          <div className="flex items-center">
            <h5 className="text-sm px-4 text-gray-900 dark:text-gray-300 dark:text-opacity-70 font-semibold tracking-tight">
              {folder.name}
            </h5>
          </div>
        </div>

        {selected && (
          <span className="inline-block absolute top-1 right-1 z-10 text-creately-theme-primary">
            <CheckIcon className="w-6 h-6 mx-1" />
          </span>
        )}
      </div>
    </div>
  );
}
