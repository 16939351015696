import { useEffect, useRef, useState } from "react";
import Layout from "./app.layout";
import Spinner from "../components/Spinner";

export default function HelpPage(props) {
  const [isHelpPageLoading, setIsHelpPageLoading] = useState(true);
  const frameRef = useRef(null);

  // load first link to iframe on mounted
  useEffect(() => {
    // get first link
    const firstHelpLink = document.querySelector("[data-helplink]");
    frameRef.current.src = firstHelpLink.href;
    frameRef.current.addEventListener("load", handleFrameLoad);
  }, []);

  function handleFrameLoad(evt) {
    setIsHelpPageLoading(false);
  }

  function handleHelpLink(evt) {
    evt.preventDefault();
    evt.stopPropagation();
    frameRef.current.src = evt.target.href;
    setIsHelpPageLoading(true);
  }

  return (
    <Layout bgClass="bg-white">
      <div className="flex bg-white h-screen">
        <div className="hidden lg:block px-6 w-[300px] bg-creately-theme-beige shadow">
          <h2 className="text-gray-900 text-xl font-semibold tracking-wide pt-6 pb-3">
            Need Help?<small className="block">We got you covered!</small>
          </h2>

          <div className="flex flex-col justify-center space-y-2 py-4">
            <a
              href={`${process.env.REACT_APP_PHOENIX_HOST}/tour/?integration=msteams`}
              className="rounded bg-creately-theme-primary text-white font-semibold tracking-wide px-3 py-1 mt-4"
              target="_blank"
              rel="noreferrer"
              data-helplink={`${process.env.REACT_APP_PHOENIX_HOST}/tour/?integration=msteams`}
              onClick={handleHelpLink}
            >
              Explore Features
            </a>
            <a
              href={`${process.env.REACT_APP_PHOENIX_HOST}/blog/?integration=msteams`}
              className="rounded bg-creately-theme-primary text-white font-semibold tracking-wide px-3 py-1 mt-4"
              target="_blank"
              rel="noreferrer"
              data-helplink={`${process.env.REACT_APP_PHOENIX_HOST}/blog/?integration=msteams`}
              onClick={handleHelpLink}
            >
              Creately Blog
            </a>
          </div>
        </div>

        <div className="lg:hidden bg-creately-theme-beige shadow fixed bottom-0 left-0 w-full z-10">
          <div className="flex justify-center items-center space-x-2 py-2">
            <a
              href={`${process.env.REACT_APP_PHOENIX_HOST}/tour/?integration=msteams`}
              className="text-sm text-center rounded bg-creately-theme-primary text-white font-semibold tracking-wide p-1"
              target="_blank"
              rel="noreferrer"
              data-helplink={`${process.env.REACT_APP_PHOENIX_HOST}/tour/?integration=msteams`}
              onClick={handleHelpLink}
            >
              Explore Features
            </a>
            <a
              href={`${process.env.REACT_APP_PHOENIX_HOST}/blog/?integration=msteams`}
              className="text-sm text-center rounded bg-creately-theme-primary text-white font-semibold tracking-wide p-1"
              target="_blank"
              rel="noreferrer"
              data-helplink={`${process.env.REACT_APP_PHOENIX_HOST}/blog/?integration=msteams`}
              onClick={handleHelpLink}
            >
              Creately Blog
            </a>
          </div>
        </div>

        <div className="flex-grow relative pb-12 lg:pb-0">
          {isHelpPageLoading && (
            <div className="absolute flex items-center justify-center bg-creately-theme-beige top-0 left-0 right-0 bottom-0">
              <Spinner text="Loading..."></Spinner>
            </div>
          )}
          <iframe
            ref={frameRef}
            className="block w-full h-full"
            frameBorder="0"
            title="Creately Help"
          ></iframe>
        </div>
      </div>
    </Layout>
  );
}
