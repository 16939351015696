export const getUser = (gqlUserResponse) => {
  const {
    data: { user: userInfo },
  } = gqlUserResponse;
  return {
    id: userInfo.id,
    name: `${userInfo.firstName} ${userInfo.lastName}`,
    email: userInfo.email,
    image: userInfo.avatar?.filename || null,
    organization: userInfo.organization
  };
};

export const authenticate = (tenantId, teamsToken) => {
  return new Promise(async (resolve, reject) => {
    const tokenResponse = await fetch(
      `${process.env.REACT_APP_GRAVITY_HOST}/v1/provider/msteams/authenticate`,
      {
        method: "POST",
        body: JSON.stringify({ tenantId, teamsToken }),
        headers: { "Content-Type": "application/json" },
      }
    );
    const authToken = await tokenResponse.json();
    if (!tokenResponse.ok) {
      reject({
        errorCode: authToken.status,
        message: "Failed to authenticate user",
      });
    }
    if (authToken.status === 214) {
      reject({
        errorCode: 403,
        message: "Unable to exchange token from the service. Consent Required!",
      });
    }
    resolve(authToken);
  });
};

export const fetchUser = async (gravityToken) => {
  const userInfo = await fetch(process.env.REACT_APP_CREATELY_API_URL, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      "creately-gravity-token": gravityToken,
    },
    body: JSON.stringify({
      query: `{
          user {
            id
            firstName
            lastName
            avatar {filename}
            email
            organization {
              id
              name
              planName
            }
          }
        }`,
    }),
  })
    .then((res) => res.json())
    .catch((err) => console.log("WHATTT" + err));
  return { ...getUser(userInfo), gravityToken };
};
