import React from "react";
import { Link } from "react-router-dom";
import WorkspaceCard from "./WorkspaceCard";

export default function WorkspaceCardList({
  workspaces,
  dashboardPath,
  placeholderText,
}) {
  if (workspaces.length === 0) {
    return (
      <h4 className="text-center text-sm mt-6 text-creately-status-in-progress">
        {placeholderText}
      </h4>
    );
  }
  return (
    <div className="grid gap-4 md:grid-cols-2 sm:grid-cols-1">
      {workspaces.map((doc) => (
        <Link
          to={{
            pathname: `/app/${doc.id}`,
            state: { dashboardPath },
          }}
          key={doc.id}
        >
          <WorkspaceCard workspace={doc} />
        </Link>
      ))}
    </div>
  );
}
