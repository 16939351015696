import { dateSince, getPlural } from "../helpers/utils";
import classnames from "classnames";
import { CheckIcon } from "@heroicons/react/outline";
import DocumentStatusTag from "./DocumentStatusTag";

export default function WorkspaceCard(props) {
  const { workspace, selected = false } = props;
  return (
    <div className="w-full relative">
      <div
        className={classnames({
          "shadow-md border rounded overflow-hidden py-4": true,
          "bg-creately-theme-beige dark:bg-creately-theme-dark border-gray-200 dark:border-none dark:shadow-lg hover:bg-creately-theme-skin dark:hover:bg-creately-theme-charade":
            !selected,
          "bg-creately-theme-primary bg-opacity-10 border-creately-theme-primary":
            selected,
        })}
      >
        <div className="flex items-center">
          <h5 className="text-sm px-4 text-gray-900 dark:text-creately-theme-secondary dark:text-opacity-70 font-semibold tracking-tight">
            {workspace.name}
          </h5>
          <DocumentStatusTag status={workspace.status} />
        </div>
        <div className="flex items-center justify-between px-4">
          <div className="flex items-center">
            <h6 className="flex items-center text-gray-400 dark:text-creately-theme-secondary dark:text-opacity-50">
              <span className="text-xs font-medium">
                {dateSince(workspace.lastUpdated)}
              </span>
            </h6>
            {/* <span className="inline-block mx-2 border-l border-gray-300 h-3"></span>
              <h6 className="flex items-center text-gray-400">
                <span className="text-xs font-medium">Folder Name</span>
              </h6> */}
          </div>
          <div className="flex items-center">
            <h6 className="flex items-center text-gray-400 dark:text-opacity-50">
              <span className="text-xs font-medium">
                {getPlural(workspace.collaborators.length, "Collaborator")}
              </span>
            </h6>
          </div>
        </div>
      </div>
      {selected && (
        <span className="inline-block absolute top-1 right-1 z-10 text-creately-theme-primary">
          <CheckIcon className="w-6 h-6 mx-1" />
        </span>
      )}
    </div>
  );
}
