import { Link } from "react-router-dom";
import UserAvatar from "../components/UserAvatar";
import { useAuth } from "../contexts/auth.context";

export default function ConfigureHomePage() {
  const { user } = useAuth();

  return (
    <div>
      <div className="flex items-center justify-between py-6 px-4">
        {user && (
          <div className="flex items-center space-x-4">
            <UserAvatar user={user} />
            <h4 className="font-semibold text-sm text-creately-theme-charade dark:text-creately-theme-black">
              You are signed in as {user.name}
            </h4>
          </div>
        )}
      </div>
      <div className="grid grid-cols-2 gap-4 px-6">
        <Link to="/configure/folders" className="block">
          <div className="bg-white dark:bg-creately-theme-dark overflow-hidden border-b-2 border-gray-200 dark:border-none rounded-md shadow h-full p-2">
            <div className="h-full">
              <div className="flex items-center justify-center">
                <img src="/images/folder-thumbnail.png" alt="Pin a folder" />
              </div>
              <h2 className="font-semibold text-gray-800 dark:text-gray-100">Pin a Folder</h2>
              <p className="text-gray-400 dark:text-opacity-50 text-sm">
                Choose a folder to pin to channel tabs
              </p>
            </div>
          </div>
        </Link>
        <Link to="/configure/workspaces" className="block">
          <div className="bg-white dark:bg-creately-theme-dark overflow-hidden border-b-2 border-gray-200 dark:border-none rounded-md shadow h-full p-2">
            <div className="flex flex-col h-full">
              <div className="flex items-center justify-center flex-grow">
                <img src="/images/placeholder.svg" alt="Pin a folder" />
              </div>
              <h2 className="font-semibold text-gray-800 dark:text-gray-100">Pin a workspace</h2>
              <p className="text-gray-400 dark:text-opacity-50 text-sm">
                Choose a workspace to pin to channel tabs
              </p>
            </div>
          </div>
        </Link>
      </div>
    </div>
  );
}
