import { useQuery } from "@apollo/client";
import { useHistory } from "react-router-dom";
import Spinner from "../components/Spinner";
import { GET_ALL_UPDATES } from "../queries/channel";
import { ChevronLeftIcon } from "@heroicons/react/outline";
import TeamActivityCard from "../components/TeamActivityCard";

function ChannelWorkspacesPage() {
  const history = useHistory();

  const { loading, data: allUpdatesResponse } = useQuery(GET_ALL_UPDATES);

  return (
    <>
      <div className="flex items-center mx-auto p-4 lg:w-4/5">
        <button
          className="flex items-center space-x-1 text-creately-theme-primary text-sm font-bold hover:underline hover:text-green-500 duration-150 transition-colors"
          onClick={history.goBack}
        >
          <ChevronLeftIcon className="w-4 h-4" />
          Back
        </button>
      </div>

      <div className="mx-auto px-4 lg:w-4/5">
        <h3 className="font-bold text-sm text-creately-theme-charade mb-4">
          Team Updates
        </h3>
        <div className="grid gap-4 sm:grid-cols-2 lg:grid-cols-3">
          {loading && <Spinner text="Loading Team Activities" />}
          {allUpdatesResponse
            ? allUpdatesResponse.allActivities.updates.map((activity) => (
                <TeamActivityCard key={activity.title} activity={activity} />
              ))
            : null}
        </div>
      </div>
    </>
  );
}

export default ChannelWorkspacesPage;
