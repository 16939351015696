import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import classnames from "classnames";
import { useAuth } from "../contexts/auth.context";
import { useLocation, useHistory } from "react-router-dom";
import { ChevronLeftIcon } from "@heroicons/react/outline";
import { useTeams } from "../hooks/useTeams";
import { gotoDeepLink } from "../helpers/utils";
import { useTelemetry } from "../contexts/tacker.context";
import {
  getTeamsWindowContext,
  TEAMS_WINDOW_CONTEXT,
} from "../helpers/teamsHelper";
import * as microsoftTeams from "@microsoft/teams-js";

const appHost = process.env.REACT_APP_CREATELY_APP_HOST;
// const appHost = "https://app-stage.creately.com";

export default function CreatelyEditorFram({ workspace, allowEdit = false }) {
  const [appInitializing, setAppInitializing] = useState(true);
  const [showNav, setShowNav] = useState(false);
  const { user } = useAuth();
  const iFrameRef = useRef(null);
  const history = useHistory();
  const { state } = useLocation();
  const [{ teamsContext }] = useTeams();
  const diagramUrl = useMemo(() => {
    if (allowEdit) return `/d/${workspace.id}/edit`;
    return `/d/${workspace.id}/view`;
  }, [allowEdit, workspace]);
  const { telemetry } = useTelemetry();
  const trackAppLoad = useCallback(() => {
    const appContext = getTeamsWindowContext(teamsContext);
    if (appContext === TEAMS_WINDOW_CONTEXT.tab.personal) {
      telemetry.track("tab.personal.load");
    }
    if (appContext === TEAMS_WINDOW_CONTEXT.tab.channel) {
      telemetry.track("tab.channel.load");
    }
    if (appContext === TEAMS_WINDOW_CONTEXT.meeting.stage) {
      telemetry.track("meeting.stage.load");
    }
  }, [telemetry, teamsContext]);

  const handleMessage = useCallback(handleWindowMessage, [
    user,
    state,
    trackAppLoad,
  ]);

  useEffect(() => {
    window.addEventListener("message", handleMessage);
    return () => {
      window.removeEventListener("message", handleMessage);
    };
  }, [appInitializing, handleMessage]);

  function sendMessage(payload) {
    console.log(payload);
    iFrameRef.current.contentWindow.postMessage(JSON.stringify(payload), "*");
  }

  function handleWindowMessage(messageData) {
    if (messageData.origin === appHost) {
      try {
        if (messageData.data.startsWith("!_")) return;
        const eventData = JSON.parse(messageData.data);
        if (
          eventData.source === "creately" &&
          eventData.event === "document:load"
        ) {
          sendMessage({
            event: "user:setToken",
            data: {
              token: user.gravityToken,
            },
          });
          sendMessage({
            event: "app:enablePluginOpts",
            data: {
              pluginApp: "msteams",
              templateSelect: false,
            },
          });
          setAppInitializing(false);
        }
        if (
          eventData.source === "creately" &&
          eventData.event === "document:close"
        ) {
          console.log("APP CLOSE EVENT --- RECIEVED");
        }
        if (
          eventData.source === "creately" &&
          eventData.event === "document:visible"
        ) {
          setShowNav(true);
          if (state && state.starter) {
            sendMessage({
              event: "use:template",
              data: {
                templateId: "XYZ",
              },
            });
          }
          trackAppLoad();
        }
        if (
          eventData.source === "creately" &&
          eventData.event === "feature:upgrade"
        ) {
          microsoftTeams.monetization.openPurchaseExperience((e) => {
            console.log("purchase experience callback is being called");
            console.log(e);
            if (!!e && typeof e !== "string") {
              console.log(JSON.stringify(e));
            }
            return;
          });
        }
      } catch (err) {
        console.log("ERROR POST --- ", messageData);
        console.error("ERROR --- ", err);
      }
    }
  }

  function trackAppExit() {
    const appContext = getTeamsWindowContext(teamsContext);
    if (appContext === TEAMS_WINDOW_CONTEXT.tab.personal) {
      telemetry.track("tab.personal.quit");
    }
    if (appContext === TEAMS_WINDOW_CONTEXT.tab.channel) {
      telemetry.track("tab.channel.quit");
    }
    if (appContext === TEAMS_WINDOW_CONTEXT.meeting.stage) {
      telemetry.track("meeting.stage.quit");
    }
  }

  function navigateBack() {
    trackAppExit();
    console.log("[TEAMS CONTEXT] --- ", teamsContext);
    if (!teamsContext.subEntityId) {
      history.push(state.dashboardPath);
    } else {
      gotoDeepLink({ subEntityId: "" });
    }
  }

  return (
    <div className="h-screen">
      {state && showNav && (
        <div className="fixed left-0 top-1/4 transform -translate-y-1/2 z-10">
          <div className="group relative">
            <span className="bg-creately-theme-charade absolute hidden group-hover:inline-block text-white text-xs rounded py-1 px-4 top-1/2 left-full translate-x-2 -translate-y-1/2">
              Back
            </span>
            <button
              className="flex items-center text-creately-theme-charade bg-white border-creately-theme-secondary border text-sm font-bold hover:underline hover:bg-opacity-95 duration-150 transition-colors rounded-tr-md rounded-br-md h-16 p-1"
              onClick={() => navigateBack()}
            >
              <ChevronLeftIcon className="w-4 h-4" />
            </button>
          </div>
        </div>
      )}
      <iframe
        ref={iFrameRef}
        className={classnames({
          "w-full h-full": true,
          hidden: appInitializing,
        })}
        src={`${appHost}${diagramUrl}`}
        frameBorder="0"
        title="Creately Editor"
      ></iframe>
    </div>
  );
}
