import { useMutation, useQuery } from "@apollo/client";
import { useState, useMemo } from "react";
import { Link, useParams, useHistory } from "react-router-dom";
import { orderBy, get } from "lodash";
import { CREATE_TEAM_WORKSPACE, GET_ALL_WORKSPACES } from "../queries/channel";
import Modal from "../components/Modal";
import Spinner from "../components/Spinner";
import WorkspaceThumbnail from "../components/WorkspaceThumbnail";
import {
  ChevronLeftIcon,
  PlusIcon,
  LightningBoltIcon,
} from "@heroicons/react/solid";
import { BrowserView } from "react-device-detect";
import { useTelemetry } from "../contexts/tacker.context";

function ChannelWorkspacesPage() {
  const [isOpen, setIsOpen] = useState(false);
  const { folderId } = useParams();
  const history = useHistory();
  const { telemetry } = useTelemetry();

  const { loading, data: allWorkspaceResponse } = useQuery(GET_ALL_WORKSPACES, {
    variables: { folderId },
    fetchPolicy: "no-cache",
  });

  const allWorkspaces = useMemo(() => {
    if (!allWorkspaceResponse) return null;
    const wkspSharedByOthers = get(allWorkspaceResponse, "folder.documents");
    return orderBy(wkspSharedByOthers, ["lastUpdated"], "desc");
  }, [allWorkspaceResponse]);

  const [documentCreate] = useMutation(CREATE_TEAM_WORKSPACE);

  async function createWorkspace(workspaceName) {
    const {
      data: { documentCreateWithPrivacyLevel: document },
    } = await documentCreate({
      variables: {
        documentInput: { name: workspaceName, projectId: folderId },
      },
      refetchQueries: [GET_ALL_WORKSPACES],
      onCompleted: () => setIsOpen(false),
    });
    return document.id;
  }

  function handleQuickStart() {
    createWorkspace("Untitled Workspace").then((workspaceId) => {
      telemetry.track("workspace.create", {
        value1Type: "Location",
        value1: "Quick Start",
      });
      history.push({
        pathname: `/app/${workspaceId}`,
        state: {
          starter: true,
          dashboardPath: `/channel/${folderId}/workspaces`,
        },
      });
    });
  }

  return (
    <>
      <Modal
        title="Create Team Workspace"
        placeholder="Your New Workspace"
        buttonText="Create Workspace"
        visible={isOpen}
        onSubmit={(formInput) => {
          createWorkspace(formInput.workspaceName);
          telemetry.track("workspace.create", {
            value1Type: "Location",
            value1: "Typical",
          });
        }}
        onClose={() => setIsOpen(false)}
      />
      <div className="flex items-center mx-auto p-4 lg:w-4/5">
        <Link
          className="flex items-center space-x-1 text-creately-theme-primary text-sm font-bold hover:underline hover:text-green-500 duration-150 transition-colors"
          to={`/channel/${folderId}`}
        >
          <ChevronLeftIcon className="w-4 h-4" />
          Dashboard
        </Link>
      </div>

      <BrowserView>
        <div className="mx-auto px-4 lg:w-4/5 mt-4">
          <h3 className="font-semibold text-creately-theme-charade dark:text-creately-theme-secondary dark:text-opacity-70">
            Get Started from Scratch or find right tools for you
          </h3>
          <div className="flex items-center space-x-2 my-2">
            <button
              className="flex w-full sm:w-auto text-creately-theme-orange items-center bg-creately-theme-yellow bg-opacity-10 border border-creately-theme-orange p-3 rounded"
              onClick={handleQuickStart}
            >
              <LightningBoltIcon className="w-6 h-6" />

              <div className="flex flex-col items-start px-3">
                <h4 className="text-sm font-semibold text-creately-theme-orange">
                  Quick Start
                </h4>
                <p className="text-gray-400 text-xs">
                  Use built-in toolkits & templates
                </p>
              </div>
            </button>
            <button
              className="flex w-full sm:w-auto text-creately-theme-primary items-center bg-creately-theme-primary bg-opacity-10 border border-creately-theme-primary p-3 rounded"
              onClick={() => setIsOpen(true)}
            >
              <PlusIcon className="w-6 h-6" />

              <div className="flex flex-col items-start px-3">
                <h4 className="text-sm font-semibold text-creately-theme-primary">
                  Create a Workspace
                </h4>
                <p className="text-gray-400 text-xs">Start from scratch</p>
              </div>
            </button>
          </div>
        </div>
      </BrowserView>
      <div className="mx-auto px-4 lg:w-4/5">
        <h3 className="font-semibold text-creately-theme-charade dark:text-creately-theme-secondary dark:text-opacity-70 mb-4">
          Your Team&lsquo;s Workspaces
        </h3>
        <div className="grid gap-4 sm:grid-cols-2 lg:grid-cols-4">
          {loading && <Spinner text="Loading All Team Workspaces" />}
          {allWorkspaces
            ? allWorkspaces.map((doc) => (
                <Link
                  to={{
                    pathname: `/app/${doc.id}`,
                    state: { dashboardPath: `/channel/${folderId}/workspaces` },
                  }}
                  key={doc.id}
                >
                  <WorkspaceThumbnail workspace={doc} />
                </Link>
              ))
            : null}
        </div>
      </div>
    </>
  );
}

export default ChannelWorkspacesPage;
