import { useMemo } from "react";
import { Route, Switch, useRouteMatch } from "react-router-dom";
import {
  getTeamsWindowContext,
  TEAMS_WINDOW_CONTEXT,
} from "../helpers/teamsHelper";
import { useTeams } from "../hooks/useTeams";
import ConfigureHomePage from "./home.page";
import ConfigureWorkspacesPage from "./workspaces.page";
import Layout from "../common/app.layout";
import ConfigureFolderPage from "./folders.page";

function ConfigurationIndexPage() {
  const [{ teamsContext }] = useTeams();
  const teamsWindowContext = useMemo(
    () => getTeamsWindowContext(teamsContext),
    [teamsContext]
  );
  const { path } = useRouteMatch();

  console.log(teamsWindowContext);

  if (
    teamsWindowContext === TEAMS_WINDOW_CONTEXT.config.meeting ||
    teamsWindowContext === TEAMS_WINDOW_CONTEXT.config.group
  ) {
    return <ConfigureWorkspacesPage />;
  }

  return (
    <Layout bgClass="bg-white">
      <Switch>
        <Route exact path={path} component={ConfigureHomePage} />
        <Route
          exact
          path={`${path}/workspaces`}
          component={ConfigureWorkspacesPage}
        />
        <Route exact path={`${path}/folders`} component={ConfigureFolderPage} />
      </Switch>
    </Layout>
  );
}

export default ConfigurationIndexPage;
