import { useQuery } from "@apollo/client";
import { useParams } from "react-router-dom";
import Layout from "../common/app.layout";
import { GET_WORKSPACE_INFO } from "../queries/editor";
import { useTeams } from "../hooks/useTeams";
import {
  getTeamsWindowContext,
  TEAMS_WINDOW_CONTEXT,
} from "../helpers/teamsHelper";
import { useMemo } from "react";
import Spinner from "../components/Spinner";
import RightSidebar from "../components/RightSidebar";
import CreatelyEditorFrame from "../components/CreatelyEditorFrame";

function ApplicationEditorPage() {
  const { workspaceId } = useParams();
  const [{ teamsContext }] = useTeams();
  const teamsWindowContext = useMemo(
    () => getTeamsWindowContext(teamsContext),
    [teamsContext]
  );

  const { loading, data: documentInfoResponse } = useQuery(GET_WORKSPACE_INFO, {
    variables: { documentId: workspaceId },
    fetchPolicy: 'no-cache'
  });

  if (loading) {
    return <Spinner text="Loading Creately Editor" />;
  }

  if (
    documentInfoResponse &&
    teamsWindowContext === TEAMS_WINDOW_CONTEXT.meeting.sidepanel
  ) {
    return <RightSidebar workspace={documentInfoResponse.document} />;
  }

  return (
    <Layout>
      <CreatelyEditorFrame
        workspace={documentInfoResponse.document}
        allowEdit={["web", "desktop"].includes(teamsContext.hostClientType)}
      />
    </Layout>
  );
}

export default ApplicationEditorPage;
