import { useMemo, useState } from "react";
import { Link, useParams, useHistory } from "react-router-dom";
import { useMutation, useQuery } from "@apollo/client";
import { get, orderBy } from "lodash";
import { ChevronLeftIcon, PlusIcon } from "@heroicons/react/outline";
import { LightningBoltIcon } from "@heroicons/react/solid";
import Modal from "../components/Modal";
import Spinner from "../components/Spinner";
import WorkspaceThumbnail from "../components/WorkspaceThumbnail";
import {
  CREATE_PRIVATE_WORKSPACE,
  GET_ALL_PERSONAL_WORKSPACES,
} from "../queries/personal";
import { BrowserView } from "react-device-detect";
import { useTelemetry } from "../contexts/tacker.context";

function PersonalWorkspacesPage() {
  const [isOpen, setIsOpen] = useState(false);
  const { folderId } = useParams();
  const history = useHistory();
  const { telemetry } = useTelemetry();

  const { loading, data: allDiagramsResponse } = useQuery(
    GET_ALL_PERSONAL_WORKSPACES,
    { fetchPolicy: "no-cache" }
  );

  const [documentCreate] = useMutation(CREATE_PRIVATE_WORKSPACE, {
    refetchQueries: [GET_ALL_PERSONAL_WORKSPACES],
    onCompleted: () => setIsOpen(false),
  });

  const allDiagrams = useMemo(() => {
    if (!allDiagramsResponse) return null;
    const privateWorkspaces = get(
      allDiagramsResponse,
      "user.organization.documents"
    );
    return orderBy(privateWorkspaces, ["lastUpdated"], "desc");
  }, [allDiagramsResponse]);

  async function createWorkspace(workspaceName) {
    const {
      data: { documentCreate: document },
    } = await documentCreate({
      variables: {
        documentInput: { name: workspaceName, projectId: folderId },
      },
      refetchQueries: [GET_ALL_PERSONAL_WORKSPACES],
      onCompleted: () => setIsOpen(false),
    });
    return document.id;
  }

  function handleQuickStart() {
    createWorkspace("Untitled Workspace").then((workspaceId) => {
      telemetry.track("workspace.create", {
        value1Type: "Location",
        value1: "Quick Start",
      });
      history.push({
        pathname: `/app/${workspaceId}`,
        state: { starter: true, dashboardPath: "/personal" },
      });
    });
  }

  return (
    <>
      <Modal
        title="Create Personal Workspace"
        placeholder="Your New Workspace"
        buttonText="Create Workspace"
        visible={isOpen}
        onSubmit={(formInput) => {
          createWorkspace(formInput.workspaceName);
          telemetry.track("workspace.create", {
            value1Type: "Location",
            value1: "Quick Start",
          });
        }}
        onClose={() => setIsOpen(false)}
      />
      <div className="flex items-center mx-auto p-4 lg:w-4/5">
        <Link
          className="flex space-x-2 items-center text-creately-theme-primary dark:text-creately-theme-black text-sm font-semibold hover:underline hover:text-green-500 duration-150 transition-colors"
          to="/personal"
        >
          <ChevronLeftIcon className="w-4 h-4" />
          Dashboard
        </Link>
      </div>

      <BrowserView>
        <div className="mx-auto px-4 lg:w-4/5">
          <h3 className="font-semibold text-creately-theme-charade dark:text-creately-theme-secondary dark:text-opacity-70">
            Get Started from Scratch or find right tools for you
          </h3>
          <div className="flex items-center space-x-2 my-2">
            <button
              className="flex w-full sm:w-auto text-creately-theme-orange items-center bg-creately-theme-yellow bg-opacity-10 border border-creately-theme-orange p-3 rounded"
              onClick={handleQuickStart}
            >
              <LightningBoltIcon className="w-6 h-6" />

              <div className="flex flex-col items-start px-3">
                <h4 className="text-sm font-semibold text-creately-theme-orange">
                  Quick Start
                </h4>
                <p className="text-gray-400 text-xs">
                  Use built-in toolkits & templates
                </p>
              </div>
            </button>
            <button
              className="flex w-full sm:w-auto text-creately-theme-primary items-center bg-creately-theme-primary bg-opacity-10 border border-creately-theme-primary p-3 rounded"
              onClick={() => setIsOpen(true)}
            >
              <PlusIcon className="w-6 h-6" />
              <div className="flex flex-col items-start px-6">
                <h4 className="text-sm font-semibold text-creately-theme-primary">
                  Create a Workspace
                </h4>
                <p className="text-gray-400 text-xs">
                  Blank, Toolsets, Templates
                </p>
              </div>
            </button>
          </div>
        </div>
      </BrowserView>
      <div className="mx-auto px-4 lg:w-4/5">
        <h3 className="font-semibold text-creately-theme-charade dark:text-creately-theme-secondary dark:text-opacity-70 mb-4">
          Your workspaces
        </h3>
        {loading && <Spinner text="Loading All Workspaces" />}
        <div className="grid gap-4 sm:grid-cols-2 lg:grid-cols-4">
          {allDiagrams
            ? allDiagrams.map((doc) => (
                <Link
                  to={{
                    pathname: `/app/${doc.id}`,
                    state: { dashboardPath: "/personal/all" },
                  }}
                  key={doc.id}
                >
                  <WorkspaceThumbnail workspace={doc} />
                </Link>
              ))
            : null}
        </div>
      </div>
    </>
  );
}

export default PersonalWorkspacesPage;
